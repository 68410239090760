<div ngbDropdown #dropdown="ngbDropdown" class="d-inline-block commonInputContainer w-100" (openChange)="openChange($event)">
    <label *ngIf="inputLabel" [for]="id" [ngClass]="smallInput ? 'f-11' : 'f-12'" class="form-label"><span class="label-text">{{inputLabel | translate}}</span><span *ngIf="isRequired" class="required-color">*</span></label>
    <div class="time_range_inputs" (click)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker) ; changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)">
        <input appTimeFormatCheck autocomplete="off" readonly [disabled]="inputDisabled" [name]="name+'1'" [id]="id+'1'" appTimeFormatCheck class="form-control invisible" placeholder="HH:MM TT" type="text" ngbDropdownToggle [(ngModel)]="timeRangePickerValue1" [ngModel]="timeRangePickerValue1 | formatTimeInput" (ngModelChange)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker)" [required]="isRequired" (click)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker)" />
        <input appTimeFormatCheck autocomplete="off" readonly [disabled]="inputDisabled" [name]="name+'2'" [id]="id+'2'" appTimeFormatCheck class="form-control invisible" placeholder="HH:MM TT" type="text" ngbDropdownToggle [(ngModel)]="timeRangePickerValue2" [ngModel]="timeRangePickerValue2 | formatTimeInput" (ngModelChange)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)" [required]="isRequired" (click)="changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)" />
        <div class="d-flex align-items-center rangepicker_values"><span [ngClass]="timeRangePickerValue1?'color-primary-grey font-semi-bold f-12':'color-primary-darkgrey font-Regular f-10'">{{timeRangePickerValue1?(timeRangePickerValue1 | formatTimeInput):"HH:MM TT"}}</span ><span class="px-1" [ngClass]="timeRangePickerValue1?'color-primary-grey font-semi-bold f-12':'color-primary-darkgrey font-Regular f-10'">-</span> <span [ngClass]="timeRangePickerValue2?'color-primary-grey font-semi-bold f-12':'color-primary-darkgrey font-Regular f-10'">{{timeRangePickerValue2?( timeRangePickerValue2 | formatTimeInput):"HH:MM TT"}}</span> </div>
    </div>
    
    <!-- TIME SVG-->
    <svg (click)="!inputDisabled?dropdown?.toggle():null;changeCurrentTimeToPreviousValue(timeRangePickerInput?.FirstTimpePicker) ; changeCurrentTimeToPreviousValue(timeRangePickerInput?.SecondTimpePicker)" [ngClass]="{'icon-disabled': inputDisabled, 'icon-reset-visible': timeRangePickerValue1 && timeRangePickerValue2 && resetIconVisible}" class="icon-position" xmlns="http://www.w3.org/2000/svg" width="15" height="20" viewBox="0 0 20.004 20">
      <path id="Union_2" data-name="Union 2" d="M-10163,17870a10.014,10.014,0,0,1,10-10,10.014,10.014,0,0,1,10,10,10.013,10.013,0,0,1-10,10A10.013,10.013,0,0,1-10163,17870Zm1.553,0a8.462,8.462,0,0,0,8.449,8.455,8.462,8.462,0,0,0,8.449-8.455,8.457,8.457,0,0,0-8.449-8.449A8.46,8.46,0,0,0-10161.449,17870Zm11.083,3.008-3.1-2.326a.77.77,0,0,1-.31-.619v-4.648a.773.773,0,0,1,.776-.771.773.773,0,0,1,.776.771v4.264l2.786,2.09a.77.77,0,0,1,.157,1.082.769.769,0,0,1-.623.314A.789.789,0,0,1-10150.366,17873.006Z" transform="translate(10163.002 -17860)" fill="#b9b9b9"/>
    </svg>
    <!-- TIME SVG-->
     <!-- Reset Svg -->
     <ng-container *ngIf="resetIconVisible" >
        <svg class="reset-icon-position" *ngIf="timeRangePickerValue1 && timeRangePickerValue2  "  (click)="clearSelection($event);" id="Icon:_reset"
          data-name="Icon: reset" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 21">
          <g id="Group_41566" data-name="Group 41566">
            <path id="Path_35107" data-name="Path 35107"
              d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z"
              transform="translate(-4 -2)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
          </g>
          <g id="Group_41567" data-name="Group 41567" transform="translate(0.231 0.95)">
            <path id="Path_35108" data-name="Path 35108"
              d="M12.507,22A9.3,9.3,0,0,1,9.1,21.367a10.057,10.057,0,0,1-4.687-3.694,1.032,1.032,0,0,1,.32-1.478,1.053,1.053,0,0,1,1.491.317,7.438,7.438,0,0,0,3.622,2.85,7.873,7.873,0,0,0,4.687.211,7.428,7.428,0,0,0,3.835-2.639,7.972,7.972,0,0,0,1.6-4.327A7.31,7.31,0,0,0,18.58,8.174,8.015,8.015,0,0,0,14.851,5.43a7.135,7.135,0,0,0-4.687,0A8.015,8.015,0,0,0,6.435,8.174a1.053,1.053,0,0,1-1.491.317.951.951,0,0,1-.213-1.478A9.495,9.495,0,0,1,9.524,3.425a9.6,9.6,0,0,1,5.966,0,9.495,9.495,0,0,1,4.794,3.588,9.017,9.017,0,0,1,1.7,5.7,9.017,9.017,0,0,1-2.024,5.594,10.319,10.319,0,0,1-5.007,3.377,8.615,8.615,0,0,1-2.45.317Z"
              transform="translate(-4.231 -2.95)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
          </g>
        </svg>
      </ng-container>
     <!--  -->
    <div ngbDropdownMenu aria-labelledby="dropdownBasic"  [ngClass]="{ 'show': timePickerVisible }">
        <div class="d-flex gap-2">
            <ngb-timepicker [id]="'timePicker1'" [name]="'timePicker1'" [(ngModel)]="ngbTimePickerValue1" (ngModelChange)="updateTimeForFirstPicker($event)" [meridian]="true" class="time-picker-custom time_range_picker"></ngb-timepicker>
            <ngb-timepicker [id]="'timePicker2'" [name]="'timePicker2'" [(ngModel)]="ngbTimePickerValue2" (ngModelChange)="updateTimeForSecondPicker($event)" [meridian]="true" class="time-picker-custom time_range_picker"></ngb-timepicker>
        </div>
    </div>
</div>