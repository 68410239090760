
export const environment = {
    production: true,
    firebase: {
        apiKey: "AIzaSyBosyitZIJyly2sko0Bwnzkeq2Vve3TY6I",
        authDomain: "cielo-fa891.firebaseapp.com",
        projectId: "cielo-fa891",
        storageBucket: "cielo-fa891.firebasestorage.app",
        messagingSenderId: "63549732409",
        appId: "1:63549732409:web:4463275e57ae2021717fe8",
        measurementId: "G-CDQV41DK4Y",
        vapidKey: "BIZ8M1YDHa9hVszr7d4zfTtT5LcBjeJCh-QqVO8IiiJjJVF1b6Et_KHum19veIRQJZYgAAg0Z81lSRUxBcOWq1A"
    },
    quickbooks: {
        clientId: 'BBw6aZ0fwaUVjb31PttG3J0dkI47BcjLMPXNU5SfUB0nbu7EIh',
        redirectUri: 'https://mycielo.net/dashboard',
        authUri: 'https://appcenter.intuit.com/connect/oauth2',
        tokenUri: 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer'
      }
};

export const baseUrl = "https://cieloapi.mycielo.net/";
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
