<label [for]="datepicker" class="form-label">{{datePickerLabel | translate}}<span *ngIf="isRequired" class="required-color">*</span></label><div class="date-input range-datepicker">
	<div class="input-group">
    <input [required]="isRequired" [(ngModel)]="inputDate" type="text" [name]="datepickername" class="d-none">
    <div class="dp-hidden position-absolute">
      <input readonly
      [required]="isRequired"
      [name]="datepickername" 
      id="datepicker"
      class="input-calendar"
      ngbDatepicker
      #datepicker="ngbDatepicker" 
      [autoClose]="false"
      (dateSelect)="onDateSelection($event)" 
      [displayMonths]="displayMonths" 
      [dayTemplate]="dayTemplate"
      [footerTemplate]="footerTemplate" 
      outsideDays="hidden"
      tabindex="-1"
      autocomplete="off"
      [minDate]="getMinSelectableDate()"
/>
      <ng-template #dayTemplate let-date let-focused="focused" (mouseleave)="leave()">
          <span 
              class="custom-day" 
              [class.focused]="focused" 
              [class.range]="isRange(date)"
              [class.start-date]="isStartDate(date)" 
              [class.end-date]="isEndDate(date)"
              [class.faded]="isHovered(date) || isInside(date)" 
              (mouseenter)="hoveredDate = date ;mouseEnter()"
              (mouseleave)="hoveredDate = null"
              >
              {{ date.day }}
          </span>
      </ng-template>
    </div>
    <div class="input-group">
      <input readonly
          #dpDateRange
          class="input-calendar"
          [placeholder]="'MM/DD/YYYY - MM/DD/YYYY'"
          name="dpDateRange"
          autocomplete="off"
          [value]="inputDate"
          (input)="onDateRangeInputChange(dpDateRange.value)"
      />
  </div>

			<!-- Templates -->
<ng-template #footerTemplate>
	<hr class="my-0">
	<div class="action-buttons">
		<button class="btn btn-sm btn-gray text-nowrap cursor-pointer" (click)="onClear();datepicker.close()">{{'COMMON.BUTTON.BUTTONLABEL.CLEAR' | translate}}</button>
		<button class="btn btn-sm btn-blue text-nowrap cursor-pointer" (click)="onApply();datepicker.close()" [disabled]="checkDisabled()">{{'COMMON.BUTTON.BUTTONLABEL.APPLY' | translate}}</button> 
    <!-- [disabled]="fromDate && !toDate" -->
	</div>
</ng-template>
<!-- end Templates -->
        <svg class="cursor-pointer" (click)="openNewRangeDatePicker()" id="calendar_3_" data-name="calendar (3)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
            <g id="Group_41416" data-name="Group 41416">
              <g id="Group_41415" data-name="Group 41415">
                <path id="Path_35075" data-name="Path 35075" d="M17.656,1.563h-.937V0H15.156V1.563H4.844V0H3.281V1.563H2.344A2.346,2.346,0,0,0,0,3.906v13.75A2.346,2.346,0,0,0,2.344,20H17.656A2.346,2.346,0,0,0,20,17.656V3.906A2.346,2.346,0,0,0,17.656,1.563Zm.781,16.094a.782.782,0,0,1-.781.781H2.344a.782.782,0,0,1-.781-.781V7.344H18.438Zm0-11.875H1.563V3.906a.782.782,0,0,1,.781-.781h.938V4.688H4.844V3.125H15.156V4.688h1.563V3.125h.938a.782.782,0,0,1,.781.781Z" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41418" data-name="Group 41418">
              <g id="Group_41417" data-name="Group 41417">
                <rect id="Rectangle_18052" data-name="Rectangle 18052" width="1.563" height="1.563" transform="translate(2.969 8.984)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41420" data-name="Group 41420">
              <g id="Group_41419" data-name="Group 41419">
                <rect id="Rectangle_18053" data-name="Rectangle 18053" width="1.563" height="1.563" transform="translate(6.094 8.984)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41422" data-name="Group 41422">
              <g id="Group_41421" data-name="Group 41421">
                <rect id="Rectangle_18054" data-name="Rectangle 18054" width="1.563" height="1.563" transform="translate(9.219 8.984)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41424" data-name="Group 41424">
              <g id="Group_41423" data-name="Group 41423">
                <rect id="Rectangle_18055" data-name="Rectangle 18055" width="1.563" height="1.563" transform="translate(12.344 8.984)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41426" data-name="Group 41426">
              <g id="Group_41425" data-name="Group 41425">
                <rect id="Rectangle_18056" data-name="Rectangle 18056" width="1.563" height="1.563" transform="translate(15.469 8.984)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41428" data-name="Group 41428">
              <g id="Group_41427" data-name="Group 41427">
                <rect id="Rectangle_18057" data-name="Rectangle 18057" width="1.563" height="1.563" transform="translate(2.969 12.109)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41430" data-name="Group 41430">
              <g id="Group_41429" data-name="Group 41429">
                <rect id="Rectangle_18058" data-name="Rectangle 18058" width="1.563" height="1.563" transform="translate(6.094 12.109)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41432" data-name="Group 41432">
              <g id="Group_41431" data-name="Group 41431">
                <rect id="Rectangle_18059" data-name="Rectangle 18059" width="1.563" height="1.563" transform="translate(9.219 12.109)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41434" data-name="Group 41434">
              <g id="Group_41433" data-name="Group 41433">
                <rect id="Rectangle_18060" data-name="Rectangle 18060" width="1.563" height="1.563" transform="translate(12.344 12.109)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41436" data-name="Group 41436">
              <g id="Group_41435" data-name="Group 41435">
                <rect id="Rectangle_18061" data-name="Rectangle 18061" width="1.563" height="1.563" transform="translate(2.969 15.234)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41438" data-name="Group 41438">
              <g id="Group_41437" data-name="Group 41437">
                <rect id="Rectangle_18062" data-name="Rectangle 18062" width="1.563" height="1.563" transform="translate(6.094 15.234)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41440" data-name="Group 41440">
              <g id="Group_41439" data-name="Group 41439">
                <rect id="Rectangle_18063" data-name="Rectangle 18063" width="1.563" height="1.563" transform="translate(9.219 15.234)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41442" data-name="Group 41442">
              <g id="Group_41441" data-name="Group 41441">
                <rect id="Rectangle_18064" data-name="Rectangle 18064" width="1.563" height="1.563" transform="translate(12.344 15.234)" fill="#b9b9b9"/>
              </g>
            </g>
            <g id="Group_41444" data-name="Group 41444">
              <g id="Group_41443" data-name="Group 41443">
                <rect id="Rectangle_18065" data-name="Rectangle 18065" width="1.563" height="1.563" transform="translate(15.469 12.109)" fill="#b9b9b9"/>
              </g>
            </g>
        </svg>
          <ng-container *ngIf="isResetIconVisible" >
            <svg class="reset-icon-position cursor-pointer" *ngIf="inputDate"   (click)="clearSelection($event);" id="Icon:_reset"
            data-name="Icon: reset" xmlns="http://www.w3.org/2000/svg" width="15" height="15"  viewBox="0 0 18 21">
            <g id="Group_41566" data-name="Group 41566">
              <path id="Path_35107" data-name="Path 35107"
                d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z"
                transform="translate(-4 -2)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
            </g>
            <g id="Group_41567" data-name="Group 41567" transform="translate(0.231 0.95)">
              <path id="Path_35108" data-name="Path 35108"
                d="M12.507,22A9.3,9.3,0,0,1,9.1,21.367a10.057,10.057,0,0,1-4.687-3.694,1.032,1.032,0,0,1,.32-1.478,1.053,1.053,0,0,1,1.491.317,7.438,7.438,0,0,0,3.622,2.85,7.873,7.873,0,0,0,4.687.211,7.428,7.428,0,0,0,3.835-2.639,7.972,7.972,0,0,0,1.6-4.327A7.31,7.31,0,0,0,18.58,8.174,8.015,8.015,0,0,0,14.851,5.43a7.135,7.135,0,0,0-4.687,0A8.015,8.015,0,0,0,6.435,8.174a1.053,1.053,0,0,1-1.491.317.951.951,0,0,1-.213-1.478A9.495,9.495,0,0,1,9.524,3.425a9.6,9.6,0,0,1,5.966,0,9.495,9.495,0,0,1,4.794,3.588,9.017,9.017,0,0,1,1.7,5.7,9.017,9.017,0,0,1-2.024,5.594,10.319,10.319,0,0,1-5.007,3.377,8.615,8.615,0,0,1-2.45.317Z"
                transform="translate(-4.231 -2.95)" [style.fill]="dynamic_class=='filterDropdown' ? '#fff' : '#727272'" />
            </g>
          </svg>
          </ng-container>
	</div>
</div>

