import { LoadDropdownDataService } from './../../shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { DashboardService } from "./dashboard.service";
import { RealtimeService } from 'src/app/shared/services/realtime.service'
import { AppointementRequestCategory, DateModel, GetDashboardData, MarkerData, RadialChartModel, stateMappings, UsStates } from "./dashboard.model";
import { Component, OnDestroy, ViewChild } from "@angular/core";
// import 'jvectormap/jquery-jvectormap.min.js'; // Import the jvectormap library
import 'jvectormap/tests/assets/jquery-jvectormap-us-aea-en.js';
import 'jvectormap/tests/assets/jquery-jvectormap-us-lcc-en.js';
// import 'jvectormap/lib
declare var $: any;
var $mountNode = $('[data-preview="jvectormap-next"] [data-testid="mountNode"]');

import {
    ApexAnnotations,
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexGrid,
    ApexLegend,
    ApexMarkers,
    ApexNonAxisChartSeries,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTheme,
    ApexTitleSubtitle,
    ApexXAxis,
    ApexYAxis,
} from "ng-apexcharts";
import {
    AppointmentInternalStatus,
    AppointmentInternalStatusString,
} from "../appointments-and-scheduling/request-list/request-list.model";
import { Subscription, catchError } from "rxjs";
import { CommonService } from "src/app/shared/services/common.service";
import { RequestListService } from "../appointments-and-scheduling/request-list/request-list.service";
import { FormSettingsService } from "../AdminSettings/form-settings/form-settings.service";
import { FormSettingTitle, GetLookupFormEnum, Roles, SignalRGroups } from "src/app/shared/enum/common-enum";
import { ResponseModel } from "src/app/shared/models/response-model.model";
import * as moment from "moment";
import { NgbDateParserFormatter, NgbModal, NgbModalOptions } from "@ng-bootstrap/ng-bootstrap";
import { NewRangeDatePickerComponent } from "src/app/shared/common-components/new-range-date-picker/new-range-date-picker.component";
import { DropdownSections } from 'src/app/shared/common-components/load-dropdown-data/load-dropdown-data.model';
import { PermissionService } from 'src/app/shared/services/permission.service';

let primary_color = localStorage.getItem("primary_color") || "#7366ff";
let secondary_color = localStorage.getItem("secondary_color") || "#f73164";

export type ChartOptions = {
    series?: ApexAxisChartSeries;
    chart?: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    tooltip?: any;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    legend?: ApexLegend;
    labels?: string[];
    plotOptions?: ApexPlotOptions;
    fill?: ApexFill;
    responsive?: ApexResponsive[];
    pieseries?: ApexNonAxisChartSeries;
    title?: ApexTitleSubtitle;
    theme?: ApexTheme;
    colors?: string[];
    markers?: ApexMarkers;
    annotations?: ApexAnnotations;
    grid?: ApexGrid;
};

export let defaultRadialChart: ChartOptions | any = {
    chart: {
        height: 120,
        type: "radialBar",
        dropShadow: {
            enabled: false,
        },
    },
    plotOptions: {
        radialBar: {
            hollow: {
                size: "60%",
            },
            track: {
                strokeWidth: "100%",
                opacity: 1,
                background: "#E2E2E2",
                margin: 5,
            },
            dataLabels: {
                showOn: "always",
                value: {
                    color: "var(--chart-text-color)",
                    fontSize: "12px",
                    show: true,
                    offsetY: -12,
                    formatter: function (val) {
                        return val.toFixed(0); // Format the value without decimal places
                    },
                },
            },
        },
    },
    stroke: {
        lineCap: "round",
    },
    responsive: [
        {
            breakpoint: 4000,
            options: {
                chart: {
                    height: 110,
                    width: 60
                },
            },

        },
        {
            breakpoint: 1024,
            options: {
                chart: {
                    width: 75,
                },
            },
        },
        {
            breakpoint: 766,
            options: {
                chart: {
                    width: 65,
                },
            },
        },
    ],
};

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent extends CommonService implements OnDestroy {
    
    public data = defaultRadialChart;
    appointmentInternalStatus = AppointmentInternalStatus;
    radialChartList: RadialChartModel[] = [];
    getDashboardData = new GetDashboardData();
    getLookupFormEnum = GetLookupFormEnum;
    formSettingTitle = FormSettingTitle;
    languagesListData: any;
    selectedDates : string = ''
    seletedlanguage: any;
    copySeletedlanguage: any;
    filterDropdownList = {
        dateRange: [
            { id: 14, value: "14 Days" },
            { id: 30, value: "30 Days" },
            { id: 60, value: "60 Days" },
        ],
    }
    totalRequest: number;
    appointmentsByLocations: any[] = [];
    upcomingRequestCount: number;
    appointmentsByLanguages: any[]=[];
    totalLanguages: any;
    map: any;
    subscription: Subscription;
    appointementRequestCategory = AppointementRequestCategory
    appointmentInternalStatusString = AppointmentInternalStatusString; // For string Comparison
    roles = Roles;
    private dashboardDataUpdateSubscription: Subscription;
    
    constructor(
        public dashboardService: DashboardService,
        public requestListService: RequestListService,
        public formSettingsService: FormSettingsService,
        public formatter: NgbDateParserFormatter,
        private modalService : NgbModal,
        public loadDropdownDataService:LoadDropdownDataService,
        public storageService : StorageService,
        private realtimeService: RealtimeService,
        public permissionService: PermissionService,
    ) {
        super();
    }

    countryNames: any
    @ViewChild('dateRange') dateRange:any;
    //vector map -----
    title = 'new-angular';
    mapName!: string;
    seriesData: any;
    mapOptions: any;

    markersData = MarkerData;
    //vector map -----

    dropdownIds = [
        { Dsi: DropdownSections.Organizations },
    ];

    ngOnInit() {
        // this.getMetadataDropdownList();
        this.loadDashboardData();
        this.initializeMap();
        this.subscribeToRealTimeUpdates();
    }
    
    subscribeToRealTimeUpdates(): void {
        this.dashboardDataUpdateSubscription = this.realtimeService?.liveDataUpdates$.subscribe(liveData => {
            if (liveData) {
                this.processDashboardData(liveData);
            }
        });
    }

    fillDropdownData(dropDownData){
        this.loadDropdownDataService.commonGetDropdownModel.organizations = dropDownData?.organizations??[];
      }

    getStateName(stateCode) {
        return stateMappings[stateCode] || 'Unknown';
    }

    usStates = [];
    generateSeriesData(markersData) {
        this.usStates = UsStates;
        const seriesData = {};
        for (const state of this.usStates) {
            var stateName = this.getStateName(state);
            var requestCount = markersData?.find(a=>a?.state == stateName)?.count;
            seriesData[state] = this.getStateColorCode(requestCount);
            seriesData[`${state}_count`] = requestCount;
        }
        return seriesData;
    }

    getRequestCount(stateCode){
        return this.requestCountListWithStates[`${stateCode}_count`];
    }

    getStateColorCode(requestCount){
        // Define your color logic based on the specified ranges
        if (requestCount === 0) {
            return '#FFFFFF'; // White for count 0
        } else if (requestCount > 0 && requestCount <= 25) {
            return '#cce4f2'; // Light blue for count 1-25
        } else if (requestCount > 25 && requestCount <= 50) {
            return '#82bdde'; // Blue for count 26-50
        } else if(requestCount > 50){
            return '#047bbc'; // Dark blue for count 51+
        }
    }

    colorCodesListWithStates = {};
    requestCountListWithStates = {};

    initializeMap() {
        this.colorCodesListWithStates = {};
        this.requestCountListWithStates = {};
        this.seriesData = this.generateSeriesData(this.markersData);
        // Iterate over the properties of this.seriesData
        for (const key in this.seriesData) {
            if (this.seriesData?.hasOwnProperty(key)) {
                if (!key.includes('_count')) {
                    this.colorCodesListWithStates[key] = this.seriesData[key];
                } else {
                    this.requestCountListWithStates[key] = this.seriesData[key];
                }
            }
        }
        // const colorScale = this.generateColorScale();
        // Assuming you have a function to initialize/update the map with the new data
        // Update this according to your actual map component implementation
        this.map = $('#us-map');
        var self = this;
        this.map.vectorMap({
            map: 'us_lcc_en', // Use the US map projection
            backgroundColor: '#FFFFFF',
            zoomOnScroll: true,
            series: {
                legend: {
                    vertical: true
                },
                regions: [
                    {
                        values: this.colorCodesListWithStates,
                        normalizeFunction: 'polynomial',
                    }
                ],
                labels: {
                    show: true, // Enable labels to display country names
                },
                markers: this.markersData,
            },
            regionStyle: {
                initial: {
                    fill: "#FFFFFF",
                    'fill-opacity': 1, // Opacity for the fill color
                    stroke: '#707070', // Change the border color here
                    'stroke-width': 1, // Width of the border
                    'stroke-opacity': 1, // Opacity of the border
                  }

            },
            onRegionOver: function (event, code) {
                $(document).ready(function() {
                    // Assuming you have a reference to the label element
                    const jVectorMapLabel = $('.jvectormap-label');
                    if(jVectorMapLabel.length > 1){
                        jVectorMapLabel.splice(0,jVectorMapLabel.length-1)
                    }
                    // Check if the element exists before modifying it
                    if (jVectorMapLabel.length) {
                        // Customize the content of the label
                        jVectorMapLabel.html(`${self.getStateName(code)}: ${self.getRequestCount(code)}`);
                
                        // Show the label if needed
                        jVectorMapLabel.show();
                    }
                });
            },
        });
      }

    addNewLanguages(event) {
        this.getDashboardData.languageVariantId = event?.languageVariant != undefined
        ? event?.languageVariant?.id
        : event?.language?.languageVariant[0]?.id,
        this.seletedlanguage = event;
        this.loadDashboardData();
}

    filterUndefinedProperties(obj: any): any {
        const filteredObj: any = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && (obj[key] !== undefined)) {
                if((obj[key] !== null)){
                    filteredObj[key] = obj[key];
                }
            }
        }
        return filteredObj;
    }

    //#region Radial Chart Data
    mapInitializecount = 0
    callInitialApiCallCount:number = 0;
    loadDashboardData() {
        this.getDashboardData = this.filterUndefinedProperties(this.getDashboardData);
        if(this.callInitialApiCallCount ==0){
            this.subscription =  this.dashboardService
            .getDashboardData(this.getDashboardData)
            .subscribe((res) => {
                if (res?.isSucceeded) {
                    this.processDashboardData(res?.response);
                } else {
                    if (res?.message != undefined) {
                        this.toaster?.error(res?.message);
                    }
                    if (res?.errors?.length > 0) {
                        // Initialize or update the map with the new data
                        this.toaster?.error(res?.errors[0]);
                    }
                }
            });
        }
        this.callInitialApiCallCount = this.callInitialApiCallCount + 1;
    }

    processDashboardData(response: any) {
        this.callInitialApiCallCount = 0;
        this.radialChartList = response?.requestsCounts ?? [];
        this.upcomingRequestCount = response?.upcomingRequests ?? 0;
        this.appointmentsByLocations = response?.appointmentsByLocations ?? [];
        this.appointmentsByLanguages = response?.appointmentsByLanguages ?? [];
        this.totalRequest = (response && response?.requestsCounts) ?
            response?.requestsCounts?.reduce((total, item) => total + item?.count, 0) : 0;
        this.totalLanguages = response?.appointmentsByLanguages?.reduce((total, item) => total + item?.count, 0);
        this.updatemarkersData(this.appointmentsByLocations, this.markersData);
        // Update the seriesData
        this.seriesData = this.generateSeriesData(this.markersData);
        this.map?.vectorMap('get', 'mapObject').remove();
        this.initializeMap();
    }

    //#endregion
    updatemarkersData(appointmentsByLocations,markersData){
        markersData?.forEach(entry => {
            entry.count = 0;
        });
        appointmentsByLocations?.forEach(appointment => {
            const stateEntry = markersData?.find(entry => entry?.state === appointment?.state);
            if (stateEntry) {
                stateEntry.count = appointment?.count;
            }
        });
        this.markersData = [...markersData];
        this.generateSeriesData(this.markersData)
    }

    openNewRangeDatePicker(){
        const modalOption: NgbModalOptions = {
            centered: true,
            backdrop: "static",
            keyboard: false,
            size: "md",
          };
          var modalRef = this.modalService.open(NewRangeDatePickerComponent, modalOption);
          if(this.getDashboardData.dateRange.startDate && this.getDashboardData.dateRange.endDate){
			modalRef.componentInstance.fromDate = this.formatter?.parse(this.getDashboardData?.dateRange?.startDate);
			modalRef.componentInstance.toDate = this.formatter?.parse(this.getDashboardData?.dateRange?.endDate);
		}
          modalRef.result.then((res) => {
            if (res) {
                this.getDashboardData.dateRange = new DateModel();
                if(res?.fromDate){
                    const { year, month, day } = res?.fromDate;
                    const momentFromDate = moment([year, month - 1, day]);
                    this.getDashboardData.dateRange.startDate = moment(momentFromDate).format('YYYY-MM-DD');
                    var startDate =  moment(momentFromDate).format('MM/DD/YY');
                }
                if(res?.toDate){
                    const { year, month, day } = res?.toDate;
                    const momenttoDate = moment([year, month - 1, day]);
                    this.getDashboardData.dateRange.endDate = moment(momenttoDate).format('YYYY-MM-DD');
                    var endDate = moment(momenttoDate).format('MM/DD/YY');
                }
                this.selectedDates = startDate + ' - ' + endDate;
                this.loadDashboardData()
            }
        });
    }

    //#region set date range as per No. of days 
    setDateAsPerNoOfDays(noOfDays){
        if(typeof(noOfDays) == 'number'){
            this.getDashboardData.dateRange = new DateModel();
            var todayDate = moment().format('YYYY-MM-DD');
            var nextDate = moment().add(noOfDays, 'days').format('YYYY-MM-DD');
            this.getDashboardData.dateRange.startDate = todayDate;
            this.getDashboardData.dateRange.endDate = nextDate;
            this.loadDashboardData();
        }
        else if(noOfDays != undefined){
            
        }
        else{
            this.selectedDates = undefined;
            this.getDashboardData.dateRange = new DateModel();
            this.loadDashboardData();
        }
    }
    //#endregion

    //#region clear Applied Filters
    clearFilters() {
        this.getDashboardData = new GetDashboardData();
        this.seletedlanguage = undefined;
        this.dateRange.currentSelection = undefined;
        this.selectedDates = undefined;
        this.loadDashboardData();
    }
    //#endregion

    ngOnDestroy(){
        // this.realtimeService?.leaveCieloGroup(SignalRGroups.DashboardAll);
        this.dashboardDataUpdateSubscription?.unsubscribe();
        this.subscription?.unsubscribe();
    }

    disableLanguageAndClear(id , element){
        if(id == AppointementRequestCategory.LinguisticMatchCall){
            element.selectedOption = undefined;
            this.seletedlanguage=undefined;
        }
    }
}
