import { LoadDropdownDataService } from './../../../../../shared/common-components/load-dropdown-data/load-dropdown-data.service';
import { Component, ViewChild } from '@angular/core';
import { FilterModel } from '../../request-list.model';
import { RangeDatepickerComponent } from 'src/app/shared/common-components/range-datepicker/range-datepicker.component';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { RequestListService } from '../../request-list.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})

export class FilterComponent{

@ViewChild(RangeDatepickerComponent) rangeDatepickerComponent: RangeDatepickerComponent;
filterModel       = new FilterModel();

constructor(
  private ngbDateParserFormatter: NgbDateParserFormatter,
  public requestListService     :RequestListService,
  public activeModal            : NgbActiveModal,
  public loadDropdownDataService:LoadDropdownDataService
  ) {}

  reset(){
    this.filterModel = new FilterModel();
    this.rangeDatepickerComponent.onClear();
  }

  applyFilter(){
    this.activeModal.close(this.filterModel);
  }

  getSelectedDates(event){
    this.filterModel.dateRange.startDate = this.ngbDateParserFormatter.format(event.startDate);
    this.filterModel.dateRange.endDate = this.ngbDateParserFormatter.format(event.endDate);

}

}
