import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";

export class PermissionViewModel {
    Dashboard = new DashboardPermissionModel();
    Appointments = new AppointmentsPermissionModel();
    Reports = new ReportsPermissionModel();
    User_Management = new UserManagementPermissionModel();
    Role_Management = new RolesPermissionModel();
    Permission_Management = new PermissionManagementPermissionModel();
    Form_Settings = new FormSettingsPermissionModel();
    Activity_Logs = new ActivityLogsPermissionModel(); 
}

export class DashboardPermissionModel {
    AppointmentStatistics: boolean;
}

export class AppointmentsPermissionModel {
    AssignRequest: boolean;
    CancelRequest: boolean;
    CreateAppointmentRequest: boolean;
    DeleteRequest: boolean;
    UpdateRequest: boolean;
    RescheduleRequest: boolean;
    ViewAppointmentRequests: boolean;
    AcceptRequest: boolean;
    DeclineRequest: boolean;
}

export class ReportsPermissionModel {
    ExportReport: boolean;
    ViewReports: boolean;
}

export class UserManagementPermissionModel {
    CreateUser: boolean;
    DeleteUser: boolean;
    UpdateUser: boolean;
    ViewUsers: boolean;
}

export class RolesPermissionModel {
    UpdateRole: boolean;
    CreateRole: boolean;
    ViewRoles: boolean;
    DeleteRole: boolean;
}

export class PermissionManagementPermissionModel {
    ViewPermissions: boolean;
    Activate_DeactivatePermission: boolean;
}

export class FormSettingsPermissionModel{
    ViewOrganizations: boolean;
    UpdateOrganization: boolean;
    Activate_DeactivateOrganization: boolean;
    CreateOrganization: boolean;
    UpdateFormSettings: boolean;
    ViewFormSettings: boolean;
}

export class ActivityLogsPermissionModel {
    ViewLogs: boolean;
}

@Injectable({
    providedIn: "root",
})
export class PermissionService {
    public permissionViewModel = new PermissionViewModel();
    updatePermissionViewModel = new Subject<boolean>();
    permissionAfterResponse = new BehaviorSubject<PermissionViewModel>(undefined);

    constructor() {}
}