<!-- Request list page -->
<app-load-dropdown-data [ids]="dropdownIds" (emitDropdownData)="fillDropdownData($event)"></app-load-dropdown-data>
<app-breadcrumb [title]="'SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST'"
    [items]="['SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING']"
    [active_item]="'SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST'"></app-breadcrumb>
<div class="container-fluid container-height">
    <div class="row h-100">
        <div
            class="requestlistWidth col-12 px-0 h-100 card shadow d-flex flex-xxl-row flex-xl-row flex-lg-column flex-md-column gap-3">
            <ng-container>
                <div class="shadow-right col-xxl-3 p-3 h-100 filter-sidebar"
                    [ngClass]="{'visible': isOpenFilterSidebar}">
                    <div class="d-flex justify-content-between align-items-center popUpheader">
                        <div class="modalTitle">{{'COMMON.BUTTON.BUTTONLABEL.FILTER' | translate}}</div>
                        <div class="d-flex gap-3">
                            <div (click)="reset($event)" class="d-flex align-items-center cursor-pointer">

                                <svg class="me-2" id="Group_42386" data-name="Group 42386" xmlns="http://www.w3.org/2000/svg" width="13.895" height="16" viewBox="0 0 17.895 20">
                                    <g id="reset-undo" transform="translate(-4 -2)">
                                      <g id="Group_41566" data-name="Group 41566" transform="translate(4 2)">
                                        <path id="Path_35107" data-name="Path 35107" d="M10,9H5A.945.945,0,0,1,4,8V3A.945.945,0,0,1,5,2,.945.945,0,0,1,6,3V7h4a.945.945,0,0,1,1,1A.945.945,0,0,1,10,9Z" transform="translate(-4 -2)" fill="#e0532f"/>
                                      </g>
                                      <g id="Group_41567" data-name="Group 41567" transform="translate(4.231 2.95)">
                                        <path id="Path_35108" data-name="Path 35108" d="M12.458,22a9.2,9.2,0,0,1-3.389-.633,10.008,10.008,0,0,1-4.66-3.694A1.036,1.036,0,0,1,4.728,16.2a1.044,1.044,0,0,1,1.483.317,7.4,7.4,0,0,0,3.6,2.85,7.782,7.782,0,0,0,4.66.211,7.383,7.383,0,0,0,3.812-2.639,8,8,0,0,0,1.588-4.327,7.34,7.34,0,0,0-1.377-4.433A7.971,7.971,0,0,0,14.788,5.43a7.053,7.053,0,0,0-4.66,0A7.971,7.971,0,0,0,6.422,8.174a1.044,1.044,0,0,1-1.483.317.954.954,0,0,1-.212-1.478,9.378,9.378,0,0,1,15.461,0,9.055,9.055,0,0,1,1.694,5.7,9.05,9.05,0,0,1-2.012,5.594,10.256,10.256,0,0,1-4.977,3.377A8.517,8.517,0,0,1,12.458,22Z" transform="translate(-4.231 -2.95)" fill="#e0532f"/>
                                      </g>
                                    </g>
                                  </svg>
                                <span class="reset-text">{{'COMMON.BUTTON.BUTTONLABEL.RESET' |
                                    translate}}</span>
                            </div>
                            <div id="filter_button_section" (click)="isOpenFilterSidebar=false"
                                class="d-flex align-items-center cursor-pointer gap-1 Close_filter">
                               <div class="d-flex">
                                <svg id="close" xmlns="http://www.w3.org/2000/svg" width="11" height="11"
                                viewBox="0 0 20 20">
                                <g id="close_1_">
                                    <path id="Path_35127" data-name="Path 35127"
                                        d="M1.9,22.441a1.891,1.891,0,0,1-1.35-.46,1.93,1.93,0,0,1,0-2.7L16.7,2.989a1.89,1.89,0,0,1,2.688.09A1.931,1.931,0,0,1,19.476,5.6L3.235,21.981a1.891,1.891,0,0,1-1.331.46Z"
                                        transform="translate(-0.001 -2.449)" fill="#727272" />
                                    <path id="Path_35128" data-name="Path 35128"
                                        d="M19.431,21.834a1.889,1.889,0,0,1-1.329-.556L1.985,5.007a1.929,1.929,0,0,1,.207-2.7,1.885,1.885,0,0,1,2.47,0L20.873,18.576a1.929,1.929,0,0,1,.088,2.709q-.043.046-.088.089a1.887,1.887,0,0,1-1.443.46Z"
                                        transform="translate(-1.474 -1.844)" fill="#727272" />
                                </g>
                            </svg>
                               </div>
                                <div class="d-flex">
                                    <span class="close-text">{{'COMMON.BUTTON.BUTTONLABEL.CLOSE'|translate}}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <form class="theme-form mt-4" ngNativeValidate #ApplyFilterForm="ngForm">
                        <div class="h-100">
                            <div class="input-section">
                                <div class="form-group row mb-2">
                                    <div class="col-12 Request-Category">
                                        <app-dropdown [dynamic_class]="'variant-dropdown'"
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'REPORTS.REPORTING.TABLE.TABLEHEADER.REQUESTTYPE'"
                                            [dropdownValue]="filterModel?.appointmentCategoryId"
                                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.requestCategories"
                                            (dropdownValueEmit)="filterModel.appointmentCategoryId = $event"
                                            [isRequired]="false" [id]="'user_Requester'">
                                        </app-dropdown>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <div class="col-12">
                                        <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.ORGANIZATION'"
                                            [dropdownValue]="filterModel?.organizationId"
                                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.organizations"
                                            (dropdownValueEmit)="filterModel.organizationId = $event"
                                            [isRequired]="false" [id]="'user_organization'">
                                        </app-dropdown>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <div class="col-12">
                                        <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'REPORTS.REPORTING.TABLE.TABLEHEADER.INTERPRETER'"
                                            [dropdownValue]="filterModel?.interpreterId"
                                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel?.appointmentInterpreters"
                                            (dropdownValueEmit)="filterModel.interpreterId=$event" [isRequired]="false"
                                            [id]="'Interpreter'">
                                        </app-dropdown>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <div class="col-12">
                                        <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST'"
                                            [dropdownValue]="filterModel?.interpreterGenderRequestId"
                                            [dropDownList]="genderDropdownList"
                                            (dropdownValueEmit)="filterModel.interpreterGenderRequestId=$event"
                                            [isRequired]="false" [id]="'user_GenderRequest'">
                                        </app-dropdown>
                                    </div>
                                </div>
                                <div class="form-group row mb-2" id="language_dropdown">
                                    <div class="col-12">
                                        <label [for]="'user_Language'"
                                            class="form-label f-12">{{'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LANGUAGE'
                                            | translate}}</label>
                                        <app-custom-dropdown [id]="'user_Language'" [isRequired]="false"
                                            (buttonOutputEvent)="addNewLanguages($event);"
                                            [singleSelectedlanguage]="seletedlanguage"
                                            [languageDropDownData]="languagesListData" [addLanguageIcon]="false"
                                            [dynamic_class]="'user_Language'"></app-custom-dropdown>

                                        <!-- <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.LANGUAGE'"
                                            [dropdownValue]="filterModel.languageId" [dropDownList]="loadDropdownDataService.commonGetDropdownModel.languages"
                                            (dropdownValueEmit)="filterModel.languageId = $event" [isRequired]="false"
                                            [id]="'user_Language'">
                                        </app-dropdown> -->
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <!-- <div class="col-12">
                                        <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'On-Demand'" [dropdownValue]="filterModel.onDemand"
                                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.onDemand"
                                            (dropdownValueEmit)="filterModel.onDemand=$event" [isRequired]="false"
                                            [id]="'On_Demand'"></app-dropdown>
                                    </div> -->
                                    <div class="col-12">
                                        <app-range-datepicker [isRequired]="false"
                                            [datePickerLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.DATERANGE'"
                                            [datepickername]="'customDatePicker'"
                                            [isResetIconVisible]="true"
                                            [fromDateFromParent]="dateRange?.dateOrDateRange?.startDate"
                                            [toDateFromParent]="dateRange?.dateOrDateRange?.endDate" [isMinDate]="false"
                                            (selectedDates)="getSelectedDates($event)">
                                        </app-range-datepicker>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <div class="col-12">
                                        <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.FILTER.STATE'"
                                            [dropdownValue]="filterModel.locationId"
                                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.states"
                                            (dropdownValueEmit)="filterModel.locationId = $event" [isRequired]="false"
                                            [id]="'user_Location'">
                                        </app-dropdown>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <!-- <div class="col-12">
                                        <app-dropdown
                                            [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.SELECT' | translate"
                                            [dropDownLabel]="'REPORTS.REPORTING.TABLE.TABLEHEADER.SERVICETYPE'"
                                            [dropdownValue]="filterModel.serviceTypeId"
                                            [dropDownList]="loadDropdownDataService.commonGetDropdownModel.services"
                                            (dropdownValueEmit)="filterModel.serviceTypeId=$event" [isRequired]="true"
                                            [id]="'service_type'"></app-dropdown>
                                    </div> -->
                                    <div class="col-12">
                                        <!-- <app-input [inputLabel]="'Start Time Range'"
                                            (inputValueEmit)="filterModel.startTimeRange=$event"
                                            [inputValue]="filterModel.startTimeRange" [inputType]="'time'"
                                            [id]="'startTime'" [name]="'startTime'" [isRequired]="false">
                                        </app-input> -->
                                        <app-time-picker
                                            [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.STARTTIME' | translate"
                                            [id]="'startTime'" [name]="'startTime'" [isRequired]="false"
                                            [resetIconVisible]="true"
                                            [timePickerValue]="filterModel?.startTimeRange?.startTime"
                                            (timePickerValueEmit)="filterModel.startTimeRange.startTime=$event;">
                                        </app-time-picker>
                                    </div>
                                </div>
                                <div class="form-group row mb-2">
                                    <div class="col-12">
                                        <!-- <app-input [inputLabel]="'End Time Range'"
                                            (inputValueEmit)="filterModel.endTimeRange=$event"
                                            [inputValue]="filterModel.endTimeRange" [inputType]="'time'"
                                            [id]="'endTime'" [name]="'endTime'" [isRequired]="false">
                                        </app-input> -->
                                        <app-time-picker
                                            [inputLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.APPOINTMENTPOPUP.LABEL.ENDTIME' | translate"
                                            [id]="'endTime'" [name]="'endTime'" [isRequired]="false"
                                            [resetIconVisible]="true"
                                            [timePickerValue]="filterModel?.startTimeRange?.endTime"
                                            (timePickerValueEmit)="filterModel.startTimeRange.endTime=$event;">
                                        </app-time-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row mt-3">
                                <app-button [buttonLabel]="'COMMON.BUTTON.BUTTONLABEL.APPLY'"
                                    [buttonClass]="'button-primary px-3'" [buttonType]="'button'" (buttonOutputEvent)="applyFilter()">
                                </app-button>
                            </div>
                        </div>

                    </form>
                </div>
            </ng-container>
            <div
                class="col-xxl-9 col-xl-12 col-lg-12 col-md-12 py-3 card d-flex flex-column h-100 request-list-container">
                <div class="col-12 d-md-none justify-content-center mb-2 mb-md-0 d-block">
                    <app-search-input [id]="'sm-search'" [inputSearchValue]="getrequestsModel.searchText"
                        (inputSearchValueEmit)="getrequestsModel.searchText = $event"
                        (inputSearchValueEmitOnClick)="getrequestsModel.searchText = $event;getAppontmentList()"></app-search-input>
                </div>
                <div class="d-flex flex-wrap gap-2\2  gap-md-4 justify-content-md-start justify-content-between">
                    <ng-container >
                        <div class="filter_button_sm">
                            <app-button (click)="openFilterForDevice()"
                                [buttonLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.FILTER'"
                                [buttonClass]="'button-danger px-md-3 px-4'">
                            </app-button>
                        </div>
                        <div class="filter_button_md">
                            <app-button (click)="isOpenFilterSidebar=true"
                                [buttonLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.FILTER'"
                                [buttonClass]="'button-danger px-md-3 px-4'">
                            </app-button>
                        </div>
                    </ng-container>
                    
                    <div>
                        <form class="width-8rem">
                            <app-dropdown
                                [showPlaceholderText]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.STATUS' | translate"
                                [dynamic_class]="'filterDropdown'"
                                [dropdownValue]="getrequestsModel.appointmentInternalStatusId"
                                (dropdownValueEmit)="getrequestsModel.appointmentInternalStatusId = $event;getAppontmentList()"
                                [dropDownList]="loadDropdownDataService.commonGetDropdownModel.statuses" [isRequired]="true" [id]="'requester_status'">
                            </app-dropdown>
                        </form>
                    </div>
                    <div class="col-xxl-4 col-xl-5 col-lg-5 col-md-5 d-md-block d-none">
                        <app-search-input [id]="'search'" [inputSearchValue]="getrequestsModel.searchText"
                            (inputSearchValueEmit)="getrequestsModel.searchText = $event"
                            (inputSearchValueEmitOnClick)="getrequestsModel.searchText = $event;getAppontmentList()"></app-search-input>
                    </div>
                    <div *ngIf="permissionService?.permissionViewModel?.Appointments?.CreateAppointmentRequest">
                        <app-button (click)="openCreateEditRequestPopUp('','Create Req',$event)"
                            [buttonLabel]="'APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.NEW'"
                            [buttonClass]="'button-primary px-md-3 px-4'">
                        </app-button>
                    </div>
                </div>
                <div class="w-100 overflow-auto mt-3 flex-fill">
                    <table class="table">
                        <tbody>
                            <tr *ngFor="let Data of requestList ;let j=index">
                                <td class="table-border pb-3 ps-0">
                                    <div class="card overflow-x-hidden border mb-1"
                                        [ngClass]="{'border-blue':((Data?.appointmentStatus==appointmentInternalStatusString.Assigned || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigned) || (Data?.appointmentStatus==appointmentInternalStatusString.Completed || Data?.appointmentStatus==appointmentInternalStatusString.EsCompleted) || (Data?.appointmentStatus==appointmentInternalStatusString.NoShow || Data?.appointmentStatus==appointmentInternalStatusString.EsNoShow)|| (Data?.appointmentStatus === appointmentInternalStatusString.Accepted || Data?.appointmentStatus === appointmentInternalStatusString.EsAccepted)),
                                        'border-grey':(Data?.appointmentStatus==appointmentInternalStatusString.UnderReview || Data?.appointmentStatus==appointmentInternalStatusString.EsUnderReview ||Data?.appointmentStatus==appointmentInternalStatusString.Pending || Data?.appointmentStatus==appointmentInternalStatusString.EsPending) || (Data?.appointmentStatus === appointmentInternalStatusString.AssigneePending || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigneePending) || (Data?.appointmentStatus === appointmentInternalStatusString.New || Data?.appointmentStatus === appointmentInternalStatusString.EsNew) || (Data?.appointmentStatus === appointmentInternalStatusString.Approved || Data?.appointmentStatus === appointmentInternalStatusString.EsApproved),
                                        'border-red':(Data?.appointmentStatus==appointmentInternalStatusString.Cancelled || Data?.appointmentStatus==appointmentInternalStatusString.EsCancelled) || (Data?.appointmentStatus==appointmentInternalStatusString.Declined || Data?.appointmentStatus==appointmentInternalStatusString.EsDeclined || Data?.appointmentStatus==appointmentInternalStatusString.SystemDeclined || Data?.appointmentStatus==appointmentInternalStatusString.EsSystemDeclined)}">
                                        <div class="card-header py-3 px-3 py-lg-2 py-xl-2 py-md-2 py-sm-2  d-flex"
                                            [ngClass]="{'border-bottom-0':!Data?.isExpandable}">
                                            <div class="col-md-10 col-lg-10 col-xl-10 col-xxl-10 col-sm-12 d-flex gap-xl-0 gap-lg-0 align-items-md-center align-items-start cursor-pointer"
                                                (click)="navigateToLog(Data,'requestListComponent',$event)">
                                                <div class=" me-2 d-flex align-items-center">
                                                    <!-- <i class="fa-solid fa-circle" [ngClass]="{
                                                        'color-primary': (Data.appointmentStatus === appointmentInternalStatusString.Assigned || Data.appointmentStatus === appointmentInternalStatusString.EsAssigned) && userDetails?.organizations[0]?.role?.value !== roles.Interpreter,
                                                        'color-primary-grey': (Data.appointmentStatus === appointmentInternalStatusString.Pending || Data.appointmentStatus === appointmentInternalStatusString.EsPending) || (Data?.appointmentStatus === appointmentInternalStatusString.AssigneePending || Data?.appointmentStatus === appointmentInternalStatusString.EsAssigneePending) || (Data?.appointmentStatus === appointmentInternalStatusString.New || Data?.appointmentStatus === appointmentInternalStatusString.EsNew) || (Data?.appointmentStatus === appointmentInternalStatusString.Approved || Data?.appointmentStatus === appointmentInternalStatusString.EsApproved) || ((Data?.appointmentStatus === appointmentInternalStatusString.Assigned || Data?.appointmentStatus === appointmentInternalStatusString.EsAssigned) && userDetails?.organizations[0]?.role?.value === roles.Interpreter),
                                                        'color-red': (Data.appointmentStatus === appointmentInternalStatusString.Cancelled || Data.appointmentStatus === appointmentInternalStatusString.EsCancelled) || (Data?.appointmentStatus === appointmentInternalStatusString.Declined || Data?.appointmentStatus === appointmentInternalStatusString.EsDeclined)
                                                    }"></i> -->
                                                    <i class="fa-solid fa-circle color-primary"
                                                        *ngIf="((Data?.appointmentStatus==appointmentInternalStatusString.Assigned || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigned) && userDetails?.organizations[0]?.role?.value!=roles.Interpreter) || 
                                                               (Data?.appointmentStatus==appointmentInternalStatusString.Completed || Data?.appointmentStatus==appointmentInternalStatusString.EsCompleted ) || 
                                                               (Data?.appointmentStatus === appointmentInternalStatusString.Accepted || Data?.appointmentStatus === appointmentInternalStatusString.EsAccepted )|| 
                                                               (Data?.appointmentStatus==appointmentInternalStatusString.NoShow  || Data?.appointmentStatus==appointmentInternalStatusString.EsNoShow)">
                                                    </i>

                                                    <i class="fa-solid fa-circle color-primary-grey"
                                                        *ngIf="(Data?.appointmentStatus==appointmentInternalStatusString.UnderReview || Data?.appointmentStatus==appointmentInternalStatusString.EsUnderReview) ||
                                                               ( Data.appointmentStatus==appointmentInternalStatusString.Pending || Data?.appointmentStatus==appointmentInternalStatusString.EsPending) || 
                                                               (Data?.appointmentStatus === appointmentInternalStatusString.AssigneePending ||Data?.appointmentStatus === appointmentInternalStatusString.EsAssigneePending)  || 
                                                               (Data?.appointmentStatus === appointmentInternalStatusString.New || Data?.appointmentStatus === appointmentInternalStatusString.EsNew ) || 
                                                               (Data?.appointmentStatus === appointmentInternalStatusString.Approved || Data?.appointmentStatus === appointmentInternalStatusString.EsApproved) 
                                                              ">
                                                    </i>

                                                    <i class="fa-solid fa-circle color-red"
                                                        *ngIf="(Data?.appointmentStatus==appointmentInternalStatusString.Cancelled || Data?.appointmentStatus==appointmentInternalStatusString.EsCancelled) ||  
                                                               (Data?.appointmentStatus==appointmentInternalStatusString.Declined || Data?.appointmentStatus==appointmentInternalStatusString.EsDeclined) || 
                                                               (Data?.appointmentStatus==appointmentInternalStatusString.SystemDeclined || Data?.appointmentStatus==appointmentInternalStatusString.EsSystemDeclined)"
                                                               >
                                                    </i>
                                                    <span class="mx-2 cursor-pointer">
                                                        <p
                                                            class="f-12 text-wrap color-primary-grey font-semi-bold mb-0 ellipsis">
                                                            {{ Data?.appointmentStatus}}
                                                        </p>
                                                    </span>
                                                </div>
                                                <!-- for lg,xl and xxl  -->
                                                <div
                                                    class="col-10 gap-2 d-block d-md-none d-lg-flex d-xl-flex  align-items-center hide-in-sm">
                                                    <!--for large screen  -->
                                                    <div class="col-3 col-lg-3 width-in-lg">

                                                        <ng-container *ngIf="Data?.interpretationLanguages?.length ==2">
                                                            <p class="f-12 color-primary font-semi-bold text-truncate  mb-1 mb-md-0"
                                                                #no1Tooltip
                                                                [ngbTooltip]="Data?.interpretationLanguages[1]?.fromLang.value + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[1]?.fromLangVariant.value)+  (Data?.interpretationLanguages[1]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[1]?.toLang.value) + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[1]?.toLangVariant.value"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest && Data?.interpretationLanguages[1]?.fromLang.value.length > 5; else no1Tooltip">
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ? '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.fromLangVariant.value}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value?' to':'NA'}} 
                                                                {{Data?.interpretationLanguages[1]?.toLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ? '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.toLangVariant.value |
                                                                truncate:5}}
                                                            </p>
                                                        </ng-container>

                                                        <ng-container *ngIf="Data?.interpretationLanguages?.length == 1">
                                                            <p class="f-12 color-primary font-semi-bold text-truncate  mb-1 mb-md-0"
                                                                #no1Tooltip
                                                                [ngbTooltip]="Data?.interpretationLanguages[0]?.fromLang.value + (Data?.interpretationLanguages[0]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[0]?.fromLangVariant.value)+  (Data?.interpretationLanguages[0]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[0]?.toLang.value) + (Data?.interpretationLanguages[0]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[0]?.toLangVariant.value"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest && Data?.interpretationLanguages[0]?.fromLang.value.length > 5; else no1Tooltip">
                                                                {{Data?.interpretationLanguages[0]?.fromLang?.value}}
                                                                {{Data?.interpretationLanguages[0]?.fromLang?.value ? '-':''}}
                                                                {{Data?.interpretationLanguages[0]?.fromLangVariant?.value}}
                                                                {{Data?.interpretationLanguages[0]?.toLang?.value?' to':'NA'}} 
                                                                {{Data?.interpretationLanguages[0]?.toLang?.value}}
                                                                {{Data?.interpretationLanguages[0]?.fromLang?.value ? '-':''}}
                                                                {{Data?.interpretationLanguages[0]?.toLangVariant?.value |
                                                                truncate:5}}
                                                            </p>
                                                        </ng-container>
                                                        <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                            #no2Tooltip
                                                            [ngbTooltip]="Data?.matchCallLanguage[0]?.language + (Data?.matchCallLanguage[0]?.language?'-':'NA') + Data?.matchCallLanguage[0]?.variant"
                                                            *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall && Data?.matchCallLanguage[0]?.variant.value > 20 ; else  no2Tooltip">
                                                            {{Data?.matchCallLanguage[0]?.language}}
                                                            {{Data?.matchCallLanguage[0]?.variant?'-':''}}
                                                            {{Data?.matchCallLanguage[0]?.variant | truncate : 20}}
                                                        </p>
                                                        <!-- show  Time-->
                                                
                                                        <div class="d-flex gap-3 me-2">
                                                            <div class="col">
                                                                <div class="is-Hide-xl-xxl">
                                                                    <p
                                                                        class="f-10 text-nowrap text-center font-semi-bold color-primary-grey mb-0">
                                                                        {{Data?.date| date: 'MM/dd/yyyy'}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="is-Hide-xl-xxl">
                                                                    <p
                                                                        class="f-10 text-nowrap text-center font-semi-bold color-primary-grey mb-0">
                                                                        {{Data?.startTime | timeFormatAMPM}}
                                                                        {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?"-":''}}
                                                                        {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?(Data?.endTime
                                                                        | timeFormatAMPM):''}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-template #no1Tooltip>
                                                            <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                [ngbTooltip]="Data?.interpretationLanguages[1]?.fromLang.value + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[1]?.fromLangVariant.value)+  (Data?.interpretationLanguages[1]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[1]?.toLang.value) + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[1]?.toLangVariant.value"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest">
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.fromLangVariant.value}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value?' to':'NA'}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value}}</p>
                                                        </ng-template>
                                                        <ng-template #no2Tooltip>
                                                            <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall">
                                                                {{Data?.matchCallLanguage[0]?.language}}
                                                                {{Data?.matchCallLanguage[0]?.variant?'-':''}}
                                                                {{Data?.matchCallLanguage[0]?.variant | truncate : 20}}
                                                            </p>
                                                        </ng-template>

                                                    </div>
                                                    <div class="col-2">
                                                        <p class="f-10 text-truncate  font-semi-bold color-primary-grey mb-1 mb-md-0"
                                                            #no3Tooltip [ngbTooltip]="Data?.organization?.value"
                                                            *ngIf="Data?.organization?.value.length > 15 ; else no3Tooltip">
                                                            {{Data?.organization?.value}}</p>
                                                        <!-- show -->
                                                        <div class="is-Hide-xl-xxl">
                                                            <p #reqType
                                                                [ngbTooltip]="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall:interpretationRequest"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall.length:interpretationRequest.length; else reqType"
                                                                class="f-10 text-nowrap text-truncate font-semi-bold color-primary-darkgrey mb-1 mb-md-0">
                                                                {{Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall:interpretationRequest}}
                                                            </p>
                                                            <ng-template #reqType>
                                                                <p
                                                                    class="f-10 text-nowrap text-truncate font-semi-bold color-primary-darkgrey mb-1 mb-md-0">
                                                                    {{linguisticMatchCall}}</p>
                                                            </ng-template>
                                                        </div>

                                                        <ng-template #no3Tooltip>
                                                            <p
                                                                class="f-10 text-wrap font-semi-bold text-truncate text-center color-primary-grey mb-1 mb-md-0">
                                                                {{Data?.organization?.value}}</p>

                                                        </ng-template>
                                                    </div>
                                                    <!-- date time for large screen  -->
                                                    <div class="d-flex gap-3 me-2">
                                                        <div class="col">
                                                            <div class="is-Hide-lg">
                                                                <p
                                                                    class="f-10 text-nowrap text-center font-semi-bold color-primary-grey mb-0">
                                                                    {{Data?.date| date: 'MM/dd/yyyy'}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col">
                                                            <div class="is-Hide-lg">
                                                                <p
                                                                    class="f-10 text-nowrap text-center font-semi-bold color-primary-grey mb-0">
                                                                    {{Data?.startTime | timeFormatAMPM}}
                                                                    {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?"-":''}}
                                                                    {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?(Data?.endTime
                                                                    | timeFormatAMPM):''}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- date time for large screen  -->
                                                    <div class="col-2">
                                                        <div class="is-Hide-lg">
                                                            <p #reqType
                                                                [ngbTooltip]="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall:interpretationRequest"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall.length:interpretationRequest.length; else reqType"
                                                                class="f-10 text-nowrap text-truncate font-semi-bold color-primary-darkgrey mb-1 mb-md-0">
                                                                {{Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall:interpretationRequest}}
                                                            </p>
                                                            <ng-template #reqType>
                                                                <p
                                                                    class="f-10 text-nowrap text-truncate font-semi-bold color-primary-darkgrey mb-1 mb-md-0">
                                                                    {{linguisticMatchCall}}</p>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <!-- for large screen  -->
                                                </div>
                                                <!-- for lg,xl and xxl  -->

                                                <!-- for md device  -->
                                                <div
                                                    class="col-10 gap-2 d-flex d-lg-none d-xl-none d-xxl-none align-items-center hide-in-sm">
                                                    <div>
                                                        <!-- language -->
                                                        <div class="col">
                                                            <p class="f-12 color-primary font-semi-bold text-truncate  mb-1 mb-md-0"
                                                                #no1Tooltip
                                                                [ngbTooltip]="Data?.interpretationLanguages[1]?.fromLang.value + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[1]?.fromLangVariant.value)+  (Data?.interpretationLanguages[1]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[1]?.toLang.value) + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[1]?.toLangVariant.value"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest && Data?.interpretationLanguages[0]?.fromLang.value.length > 5; else no1Tooltip">
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ?'-':''}}
                                                                {{Data?.interpretationLanguages[1]?.fromLangVariant.value}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value?' to':'NA'}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ? '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.toLangVariant.value| truncate:5}}
                                                            </p>
                                                            <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                #no2Tooltip
                                                                [ngbTooltip]="Data?.matchCallLanguage[0]?.language + (Data?.matchCallLanguage[0]?.language?'-':'NA') + Data?.matchCallLanguage[0]?.variant"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall && Data?.matchCallLanguage[0]?.variant.value > 20 ; else  no2Tooltip">
                                                                {{Data?.matchCallLanguage[0]?.language}}
                                                                {{Data?.matchCallLanguage[0]?.variant?'-':''}}
                                                                {{Data?.matchCallLanguage[0]?.variant | truncate : 20}}
                                                            </p>
                                                            <ng-template #no1Tooltip>
                                                                <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                    [ngbTooltip]="Data?.interpretationLanguages[1]?.fromLang.value + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[1]?.fromLangVariant.value)+  (Data?.interpretationLanguages[1]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[1]?.toLang.value) + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[1]?.toLangVariant.value"
                                                                    *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest">
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                    '-':''}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLangVariant.value}}
                                                                    {{Data?.interpretationLanguages[1]?.toLang.value?'
                                                                    to ':'NA'}}
                                                                    {{Data?.interpretationLanguages[1]?.toLang.value}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                    '-':''}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                </p>
                                                            </ng-template>
                                                            <ng-template #no2Tooltip>
                                                                <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                    *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall">
                                                                    {{Data?.matchCallLanguage[0]?.language}}
                                                                    {{Data?.matchCallLanguage[0]?.variant?'-':''}}
                                                                    {{Data?.matchCallLanguage[0]?.variant | truncate :
                                                                    20}}</p>
                                                            </ng-template>

                                                        </div>
                                                        <!-- language -->
                                                        <!-- date time -->
                                                        <div class="d-flex gap-3 me-2">
                                                            <div class="col">
                                                                <p
                                                                    class="f-10 text-nowrap font-semi-bold color-primary-grey mb-0">
                                                                    {{Data?.date| date: 'MM/dd/yyyy'}}</p>
                                                            </div>
                                                            <div class="col">
                                                                <p
                                                                    class="f-10 text-nowrap font-semi-bold color-primary-grey mb-0">
                                                                    {{Data?.startTime | timeFormatAMPM}}
                                                                    {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?"-":''}}
                                                                    {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?(Data?.endTime
                                                                    | timeFormatAMPM):''}}</p>
                                                            </div>
                                                        </div>
                                                        <!-- date time  -->
                                                    </div>

                                                    <div>
                                                        <div class="col">
                                                            <p class="f-10 text-truncate  font-semi-bold color-primary-grey mb-1 mb-md-0"
                                                                #no3Tooltip [ngbTooltip]="Data?.organization?.value"
                                                                *ngIf="Data?.organization?.value.length > 15 ; else no3Tooltip">
                                                                {{Data?.organization?.value}}</p>
                                                            <ng-template #no3Tooltip>
                                                                <p
                                                                    class="f-10 text-wrap font-semi-bold text-truncate color-primary-grey mb-1 mb-md-0">
                                                                    {{Data?.organization?.value}}</p>
                                                            </ng-template>
                                                        </div>
                                                        <div class="col">
                                                            <p
                                                                class="f-10 text-nowrap text-truncate font-semi-bold color-primary-darkgrey mb-1 mb-md-0">
                                                                {{Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall:interpretationRequest}}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- for md device  -->

                                                <!-- for mobile device  -->
                                                <div
                                                    class="col-10 gap-1 d-flex d-md-none d-lg-none d-xl-none d-xxl-none text-success justify-content-between">
                                                    <div class="col-10">
                                                        <div class="col">
                                                            <p class="f-12 color-primary font-semi-bold text-truncate  mb-1 mb-md-0"
                                                                #no1Tooltip
                                                                [ngbTooltip]="Data?.interpretationLanguages[1]?.fromLang.value + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[1]?.fromLangVariant.value)+  (Data?.interpretationLanguages[1]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[1]?.toLang.value) + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[1]?.toLangVariant.value"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest && Data?.interpretationLanguages[1]?.fromLang.value.length > 5; else no1Tooltip">
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.fromLangVariant.value}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value?' to
                                                                ':'NA'}}
                                                                {{Data?.interpretationLanguages[1]?.toLang.value}}
                                                                {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                '-':''}}
                                                                {{Data?.interpretationLanguages[1]?.toLangVariant.value
                                                                | truncate:5}}</p>
                                                            <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                #no2Tooltip
                                                                [ngbTooltip]="Data?.matchCallLanguage[0]?.language + (Data?.matchCallLanguage[0]?.language?'-':'NA') + Data?.matchCallLanguage[0]?.variant"
                                                                *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall && Data?.matchCallLanguage[0]?.variant.value > 20 ; else  no2Tooltip">
                                                                {{Data?.matchCallLanguage[0]?.language}}
                                                                {{Data?.matchCallLanguage[0]?.variant?'-':''}}
                                                                {{Data?.matchCallLanguage[0]?.variant | truncate : 20}}
                                                            </p>
                                                            <ng-template #no1Tooltip>
                                                                <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                    [ngbTooltip]="Data?.interpretationLanguages[1]?.fromLang.value + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + (Data?.interpretationLanguages[1]?.fromLangVariant.value)+  (Data?.interpretationLanguages[1]?.toLang.value?' to ':'NA') + (Data?.interpretationLanguages[1]?.toLang.value) + (Data?.interpretationLanguages[1]?.fromLang.value ? '-':'') + Data?.interpretationLanguages[1]?.toLangVariant.value"
                                                                    *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest">
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                    '-':''}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLangVariant.value}}
                                                                    {{Data?.interpretationLanguages[1]?.toLang.value?'
                                                                    to ':'NA'}}
                                                                    {{Data?.interpretationLanguages[1]?.toLang.value}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value ?
                                                                    '-':''}}
                                                                    {{Data?.interpretationLanguages[1]?.fromLang.value}}
                                                                </p>
                                                            </ng-template>
                                                            <ng-template #no2Tooltip>
                                                                <p class="f-12 color-primary font-semi-bold text-truncate mb-1 mb-md-0"
                                                                    *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall">
                                                                    {{Data?.matchCallLanguage[0]?.language}}
                                                                    {{Data?.matchCallLanguage[0]?.variant?'-':''}}
                                                                    {{Data?.matchCallLanguage[0]?.variant | truncate :
                                                                    20}}</p>
                                                            </ng-template>

                                                        </div>
                                                        <!-- language -->
                                                        <!-- date time -->
                                                        <div class="d-flex gap-1">
                                                            <div class="col">
                                                                <p
                                                                    class="f-10 text-nowrap font-semi-bold color-primary-grey mb-0">
                                                                    {{Data?.date| date: 'MM/dd/yyyy'}}</p>
                                                            </div>
                                                            <div class="col">
                                                                <p
                                                                    class="f-10 text-nowrap font-semi-bold color-primary-grey mb-0">
                                                                    {{Data?.startTime | timeFormatAMPM}}
                                                                    {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?"-":''}}
                                                                    {{Data?.appointmentCategoryId!=requestCategory.LinguisticMatchCall?(Data?.endTime
                                                                    | timeFormatAMPM):''}}</p>
                                                            </div>
                                                        </div>
                                                        <!-- date time  -->
                                                        <div class="col">
                                                            <p class="f-10 text-truncate  font-semi-bold color-primary-grey mb-1 mb-md-0"
                                                                #no3Tooltip [ngbTooltip]="Data?.organization?.value"
                                                                *ngIf="Data?.organization?.value.length > 15 ; else no3Tooltip">
                                                                {{Data?.organization?.value}}</p>

                                                            <ng-template #no3Tooltip>
                                                                <p
                                                                    class="f-10 text-wrap font-semi-bold text-truncate color-primary-grey mb-1 mb-md-0">
                                                                    {{Data?.organization?.value}}</p>

                                                            </ng-template>
                                                        </div>
                                                        <div class="col">
                                                            <p
                                                                class="f-10 text-nowrap text-truncate font-semi-bold color-primary-darkgrey mb-1 mb-md-0">
                                                                {{Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall?linguisticMatchCall:interpretationRequest}}
                                                            </p>
                                                        </div>
                                                        <!-- Accept-decline  -->
                                                        <div class="d-flex align-items-md-center gap-3">
                                                            <!-- Data?.appointmentStatus==appointmentInternalStatusString.Accepted || Data?.appointmentStatus==appointmentInternalStatusString.EsAccepted -->
                                                            <ng-container *ngIf="(Data?.scheduleOptionsId != scheduleOptions.OnDemand)">
                                                                <div class="d-flex gap-3"
                                                                    *ngIf="(Data?.appointmentStatus==appointmentInternalStatusString.AssigneePending || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigneePending) || (Data?.appointmentStatus==appointmentInternalStatusString.Approved || Data?.appointmentStatus==appointmentInternalStatusString.EsApproved)">
                                                                    <div *ngIf="permissionService?.permissionViewModel?.Appointments?.AssignRequest && userDetails?.organizations[0]?.role?.value!=roles.Requester"
                                                                        class="mx-0 d-flex">
                                                                        <div class="col text-center" [ngClass]="{'cursor-notAllowed': Data?.isReassigningDisabled}">
                                                                            <p [ngClass]="{'assign_btn_disable': Data?.isReassigningDisabled }" class="f-14 color-primary font-semi-bold mb-0 cursor-pointer"
                                                                                (click)="openAssignInterpretersPopUp(Data,$event)">
                                                                                {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ASSIGN"
                                                                                | translate }}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <div class="d-flex gap-3"
                                                                *ngIf="(Data?.appointmentStatus==appointmentInternalStatusString.Assigned || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigned) && userDetails?.organizations[0]?.role?.value!=roles.Interpreter">
                                                                <div *ngIf="permissionService?.permissionViewModel?.Appointments?.RescheduleRequest && userDetails?.organizations[0]?.role?.value!=roles.Requester"
                                                                    class="mx-0 d-flex">
                                                                    <div class="col text-center" [ngClass]="{'cursor-notAllowed': Data?.isReassigningDisabled}">
                                                                        <p [ngClass]="{'assign_btn_disable': Data?.isReassigningDisabled }" class="f-14 color-primary font-semi-bold mb-0 cursor-pointer"
                                                                            (click)="openAssignInterpretersPopUp(Data,$event)">
                                                                            {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REASSIGN"
                                                                            | translate }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex gap-3"
                                                                *ngIf="((Data?.appointmentStatus === appointmentInternalStatusString.Pending || Data?.appointmentStatus === appointmentInternalStatusString.New) && userDetails?.organizations[0]?.role?.value !== roles.Requester) || (Data?.appointmentStatus === appointmentInternalStatusString.AssigneePending && userDetails?.organizations[0]?.role?.value === roles.Interpreter)">
                                                                <div class="mx-0 d-flex"
                                                                    *ngIf="permissionService?.permissionViewModel?.Appointments?.AcceptRequest">
                                                                    <div class="col text-center">
                                                                        <p class="f-14 color-primary font-semi-bold mb-0 cursor-pointer"
                                                                            (click)="openAcceptRequestPopUp(Data ,$event)">
                                                                            {{ userDetails?.organizations[0]?.role?.value === roles?.Interpreter ? ("APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ACCEPT"| translate) : ("APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPROVE"| translate) }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div class="d-flex"
                                                                    *ngIf="permissionService?.permissionViewModel?.Appointments?.DeclineRequest">
                                                                    <div class="col text-center">
                                                                        <p class="f-14 color-red font-semi-bold mb-0 cursor-pointer"
                                                                            (click)="declineRequestConfirmation(Data?.id ,$event)">
                                                                            {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.DECLINE"
                                                                            | translate}}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            
                                                            <ng-container *ngIf="userDetails?.organizations[0]?.role.value==roles.Requester && permissionService?.permissionViewModel?.Appointments?.CancelRequest" >
                                                                <div class="d-flex gap-3"
                                                                    *ngIf="(Data?.appointmentStatus === appointmentInternalStatusString?.Pending || Data?.appointmentStatus === appointmentInternalStatusString?.EsPending) || (Data?.appointmentStatus === appointmentInternalStatusString.UnderReview || Data?.appointmentStatus === appointmentInternalStatusString.EsUnderReview)|| (Data?.appointmentStatus === appointmentInternalStatusString?.Accepted || Data?.appointmentStatus === appointmentInternalStatusString?.EsAccepted)">
                                                                    <div class="mx-0 d-flex">
                                                                        <div class="col text-center">
                                                                            <p class="f-14 color-red font-semi-bold mb-0 cursor-pointer"
                                                                                (click)="getdeclined(Data?.id ,$event)">
                                                                                {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CANCEL"
                                                                                | translate}}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>

                                                            <div class="d-flex gap-3 align-items-start">
                                                                <i class="fa-solid cursor-pointer color-primary-grey f-21" 
                                                                [ngClass]="Data.isExpandable ? 'fa-caret-up' : 'fa-caret-down'"
                                                                    (click)="toggle(Data,$event)"></i>
                                                            </div>
                                                        </div>
                                                        <!-- accept declicne  -->
                                                    </div>
                                                   
                                                    <ng-container *ngIf="permissionService?.permissionViewModel?.Appointments?.UpdateRequest && (!((Data?.appointmentStatus==appointmentInternalStatusString?.Completed || Data?.appointmentStatus==appointmentInternalStatusString?.EsCompleted) || (Data?.appointmentStatus==appointmentInternalStatusString?.Cancelled || Data?.appointmentStatus==appointmentInternalStatusString?.EsCancelled) || (Data?.appointmentStatus==appointmentInternalStatusString?.Declined || Data?.appointmentStatus==appointmentInternalStatusString?.EsDeclined) || (Data?.appointmentStatus==appointmentInternalStatusString?.NoShow || Data?.appointmentStatus==appointmentInternalStatusString?.EsNoShow) ||  (Data?.appointmentStatus==appointmentInternalStatusString?.SystemDeclined || Data?.appointmentStatus==appointmentInternalStatusString?.EsSystemDeclined)))"> 
                                                        <div class=" d-flex justify-content-end px-2 sm-edit-icon">
                                                            <div class="d-flex align-items-center"
                                                                (click)="openCreateEditRequestPopUp(Data,'Edit Req',$event)">
                                                                <i
                                                                    class="fa-regular f-16 cursor-pointer fa-pen-to-square color-primary-grey"></i>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <!-- for mobile device  -->
                                            </div>
                                            <!-- accept decline for lg,xl,xxl and md  -->
                                            
                                            <div
                                                class="col-2 d-flex align-items-md-center align-items-start justify-content-end gap-3 hide-in-sm">
                                                <ng-container *ngIf="Data?.isAllowedToComplete">
                                                    <p *ngIf="userDetails?.organizations[0]?.role?.value == roles?.Admin || userDetails?.organizations[0]?.role?.value == roles?.SuperAdmin" (click)="openCreateEditRequestPopUp(Data,'Edit Req',$event ,true)" class="close_button ms-2 d-flex text-nowrap mb-0 align-items-center color-primary font-semi-bold cursor-pointer">{{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CLOSEREQUEST" | translate}}</p>
                                                </ng-container>
                                                <ng-container *ngIf="permissionService?.permissionViewModel?.Appointments?.UpdateRequest && (!((Data?.appointmentStatus==appointmentInternalStatusString?.Completed || Data?.appointmentStatus==appointmentInternalStatusString?.EsCompleted) || (Data?.appointmentStatus==appointmentInternalStatusString?.Cancelled || Data?.appointmentStatus==appointmentInternalStatusString?.EsCancelled) || (Data?.appointmentStatus==appointmentInternalStatusString?.Declined || Data?.appointmentStatus==appointmentInternalStatusString?.EsDeclined) || (Data?.appointmentStatus==appointmentInternalStatusString?.NoShow || Data?.appointmentStatus==appointmentInternalStatusString?.EsNoShow) ||  (Data?.appointmentStatus==appointmentInternalStatusString?.SystemDeclined || Data?.appointmentStatus==appointmentInternalStatusString?.EsSystemDeclined)))"> 
                                                    <div (mouseover)="checkAppointmentTime(Data)" [ngClass]="{'cursor-notAllowed':Data?.checkingRequestIsDuringOrAfter?.isEditIconDisableForInterpreter === true}"  class="d-flex align-items-center">
                                                        <i (click)="openCreateEditRequestPopUp(Data,'Edit Req',$event)" [ngClass]="{'pe-none':Data?.checkingRequestIsDuringOrAfter?.isEditIconDisableForInterpreter === true}"  class="fa-regular cursor-pointer fa-pen-to-square color-primary-grey"></i>
                                                    </div>
                                                </ng-container>

                                                <div class="d-md-flex d-none align-items-md-center gap-3">
                                                    <ng-container *ngIf="(Data?.scheduleOptionsId != scheduleOptions.OnDemand)">
                                                        <div class="d-flex gap-3"
                                                            *ngIf="(Data?.appointmentStatus==appointmentInternalStatusString.AssigneePending || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigneePending) || 
                                                            (Data?.appointmentStatus==appointmentInternalStatusString.Approved || Data?.appointmentStatus==appointmentInternalStatusString.EsApproved)">
                                                            <div *ngIf="permissionService?.permissionViewModel?.Appointments?.AssignRequest && userDetails?.organizations[0]?.role?.value!=roles.Requester"
                                                                class="mx-0 d-flex">
                                                                <div  class="col text-center" [ngClass]="{'cursor-notAllowed':Data?.isReassigningDisabled}">
                                                                    <p [ngClass]="{'assign_btn_disable':Data?.isReassigningDisabled }" class="f-14 color-primary font-semi-bold mb-0 cursor-pointer"
                                                                        (click)="openAssignInterpretersPopUp(Data,$event)">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ASSIGN"
                                                                        | translate }}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <div class="d-flex gap-3"
                                                        *ngIf="(Data?.appointmentStatus==appointmentInternalStatusString.Assigned || Data?.appointmentStatus==appointmentInternalStatusString.EsAssigned) && userDetails?.organizations[0]?.role?.value!=roles.Interpreter">
                                                        <div *ngIf="permissionService?.permissionViewModel?.Appointments?.RescheduleRequest && userDetails?.organizations[0]?.role?.value!=roles.Requester"
                                                            class="mx-0 d-flex">
                                                            <div class="col text-center" [ngClass]="{'cursor-notAllowed': Data?.isReassigningDisabled}">
                                                                <p [ngClass]="{'assign_btn_disable': Data?.isReassigningDisabled }" class="f-14 color-primary font-semi-bold mb-0 cursor-pointer"
                                                                    (click)="openAssignInterpretersPopUp(Data,$event)">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REASSIGN"
                                                                    | translate }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex gap-3"
                                                        *ngIf="(((Data?.appointmentStatus === appointmentInternalStatusString.Pending || Data?.appointmentStatus === appointmentInternalStatusString.EsPending) ||
                                                         (Data?.appointmentStatus === appointmentInternalStatusString.New || Data?.appointmentStatus === appointmentInternalStatusString.EsNew)) && userDetails?.organizations[0]?.role?.value !== roles.Requester) || 
                                                         ((Data?.appointmentStatus === appointmentInternalStatusString.AssigneePending || Data?.appointmentStatus === appointmentInternalStatusString.EsAssigneePending) && userDetails?.organizations[0]?.role?.value === roles.Interpreter)">
                                                        <div class="mx-0 d-flex"
                                                            *ngIf="permissionService?.permissionViewModel?.Appointments?.AcceptRequest">
                                                            <div class="col text-center">
                                                                <p class="f-14 color-primary font-semi-bold mb-0 cursor-pointer"
                                                                    (click)="openAcceptRequestPopUp(Data , $event)">
                                                                    {{ userDetails?.organizations[0]?.role?.value === roles?.Interpreter ? ("APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ACCEPT"| translate) : ("APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPROVE"| translate) }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div class="d-flex"
                                                            *ngIf="permissionService?.permissionViewModel?.Appointments?.DeclineRequest">
                                                            <div class="col text-center">
                                                                <p class="f-14 color-red font-semi-bold mb-0 cursor-pointer"
                                                                    (click)="declineRequestConfirmation(Data?.id , $event)">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.DECLINE"
                                                                    | translate}}</p>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <ng-container *ngIf="userDetails?.organizations[0]?.role.value==roles.Requester && permissionService?.permissionViewModel?.Appointments?.CancelRequest" >
                                                        <div class="d-flex gap-3"
                                                            *ngIf="(Data?.appointmentStatus === appointmentInternalStatusString?.Pending || Data?.appointmentStatus === appointmentInternalStatusString?.EsPending) || (Data?.appointmentStatus === appointmentInternalStatusString.UnderReview || Data?.appointmentStatus === appointmentInternalStatusString.EsUnderReview)|| (Data?.appointmentStatus === appointmentInternalStatusString?.Accepted || Data?.appointmentStatus === appointmentInternalStatusString?.EsAccepted)">
                                                            <div class="mx-0 d-flex">
                                                                <div class="col text-center">
                                                                    <p class="f-14 color-red font-semi-bold mb-0 cursor-pointer"
                                                                        (click)="getdeclined(Data?.id , $event)">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CANCEL"
                                                                        | translate}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <div class="d-flex gap-3 align-items-start">
                                                        <i class="cursor-pointer color-primary-grey f-21 fa-solid"
                                                            [ngClass]="Data.isExpandable ? 'fa-caret-up' : 'fa-caret-down'" (click)="toggle(Data, $event)">
                                                        </i>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- accept decline for lg,xl,xxl and md  -->
                                        </div>
                                        <div class="card-body p-0 cursor-pointer"
                                            [ngClass]="{'border-red-top':((Data?.appointmentStatus==appointmentInternalStatusString.Cancelled || Data?.appointmentStatus==appointmentInternalStatusString.EsCancelled) || (Data?.appointmentStatus==appointmentInternalStatusString.Declined || Data?.appointmentStatus==appointmentInternalStatusString.EsDeclined)),
                                        'border-grey-top':((Data.appointmentStatus==appointmentInternalStatusString.Pending || Data.appointmentStatus==appointmentInternalStatusString.EsPending) || (Data?.appointmentStatus === appointmentInternalStatusString.AssigneePending || Data.appointmentStatus==appointmentInternalStatusString.EsAssigneePending) || (Data?.appointmentStatus === appointmentInternalStatusString.Accepted || Data?.appointmentStatus === appointmentInternalStatusString.EsAccepted) ||  (Data?.appointmentStatus === appointmentInternalStatusString.New || Data?.appointmentStatus === appointmentInternalStatusString.EsNew) || (Data?.appointmentStatus === appointmentInternalStatusString.Approved || Data?.appointmentStatus === appointmentInternalStatusString.EsApproved)),
                                        'border-blue-top':((Data.appointmentStatus==appointmentInternalStatusString.Assigned || Data.appointmentStatus==appointmentInternalStatusString.EsAssigned) || (Data.appointmentStatus==appointmentInternalStatusString.Completed || Data.appointmentStatus==appointmentInternalStatusString.EsCompleted) || (Data.appointmentStatus==appointmentInternalStatusString.NoShow || Data.appointmentStatus==appointmentInternalStatusString.EsNoShow))}"
                                            *ngIf="Data?.isExpandable" (click)="navigateToLog(Data,'requestListComponent',$event)">
                                            <div
                                                *ngIf="Data?.appointmentCategoryId==requestCategory.LinguisticMatchCall">
                                                <!-- for md,xl,lg And xxl  -->
                                                <ul class="list-group list-group-flush hide-in-sm">
                                                    <li class="list-group-item py-3">
                                                        <div class="col-12 d-flex">
                                                            <div
                                                                class="col-xl-6 col-lg-6 col-md-4 col-6 d-flex border-right">
                                                                <div class="col-4 px-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTOR"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.user?.value}}</p>
                                                                </div>
                                                                <div class="col-4 pe-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold text-nowrap mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPOINTMENTNUMBER"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.appointmentNumber}}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xl-6 col-lg-6 col-md-8 col-6 d-flex ps-4 gap-2">
                                                                <div class="col-4">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.LOCATION"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.location}}</p>
                                                                </div>
                                                                <div class="col-4">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.GENDERREQUEST"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.interpreterGenderRequest?.value ?? "APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST.OPTION.NOPREFERENCE"|translate}}</p>
                                                                </div>
                                                                <div class="col-4">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CALLINNUMBER"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.phoneNumber ?? 'NA'}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item py-3">
                                                        <div class="d-flex mx-0 variant_description">
                                                            <div class="col-xl-6 col-lg-6 col-md-6 p-0 col-6 d-flex border-right mb-2 "
                                                                [ngClass]="{'border-right': i%2 ==0, 'ps-4 gap-2': i%2 !=0}"
                                                                *ngFor="let item of Data.matchCallLanguage |slice:0:Data.itemLength; let i=index">
                                                                <div class="col-4"
                                                                    [ngClass]="{'px-2': i%2 ==0,'px-0': i%2 !=0}">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.VARIENT"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{item.language}} - {{item.variant}}</p>
                                                                </div>
                                                                <div class="col-6 pe-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.DESCRIPTION"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{item.description}}</p>
                                                                </div>
                                                                <div class="col-2 px-3 d-flex justify-content-end"
                                                                    *ngIf="Data.matchCallLanguage.length>2 && i==1">
                                                                    <span stopPropagation
                                                                        (click)="Data.itemLength = (Data.itemLength === Data.matchCallLanguage.length) ? 2 : Data.matchCallLanguage.length;"
                                                                        class="f-10 color-primary-grey font-semi-bold">
                                                                        {{Data.itemLength ===
                                                                        Data.matchCallLanguage.length?"-":"+"}}{{Data.matchCallLanguage.length-2}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <!-- for md,xl,lg And xxl  -->

                                                <!-- for mobile devices  -->
                                                <ul class="list-group list-group-flush hide-in-large">
                                                    <li class="list-group-item p-0">
                                                        <div class="col">
                                                            <div class="col d-flex gap-2 list-group-item p-1">
                                                                <div class="col-5 px-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTOR"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.user?.value}}</p>
                                                                </div>
                                                                <div class="col-7 pe-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold text-nowrap mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPOINTMENTNUMBER"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.appointmentNumber}}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col d-flex flex-column gap-2 list-group-item p-1">
                                                                <div class="col-12 d-flex">
                                                                    <div class="col-5 px-2">
                                                                        <p
                                                                            class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                            {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.GENDERREQUEST"
                                                                            | translate}}</p>
                                                                        <p class="f-10 color-primary-grey font-Regular">
                                                                            {{Data?.interpreterGenderRequest?.value ?? "APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST.OPTION.NOPREFERENCE"|translate}}</p>
                                                                    </div>
                                                                    <div class="col-7 pe-2">
                                                                        <p
                                                                            class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                            {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.CALLINNUMBER"
                                                                            | translate}}</p>
                                                                        <p class="f-10 color-primary-grey font-Regular">
                                                                            {{Data?.phoneNumber ?? 'NA'}}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col px-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.LOCATION"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.location}}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col d-flex flex-column gap-2 list-group-item p-1">
                                                                <div class="col d-flex mb-2 px-2"
                                                                    *ngFor="let item of Data.matchCallLanguage |slice:0:Data.itemLength; let i=index">
                                                                    <div class="col-5">
                                                                        <p
                                                                            class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                            {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.VARIENT"
                                                                            | translate}}</p>
                                                                        <p class="f-10 color-primary-grey font-Regular">
                                                                            {{item.language}} - {{item.variant}}</p>
                                                                    </div>
                                                                    <div class="col-md-7">
                                                                        <p
                                                                            class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                            {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.DESCRIPTION"
                                                                            | translate}}</p>
                                                                        <p class="f-10 color-primary-grey font-Regular">
                                                                            {{item.description}}</p>
                                                                    </div>
                                                                    <!-- <div class="col-2 px-3 d-flex justify-content-end" *ngIf="Data.matchCallLanguage.length>2 && i==1">
                                                                        <span stopPropagation (click)="Data.itemLength = (Data.itemLength === Data.matchCallLanguage.length) ? 2 : Data.matchCallLanguage.length;" class="f-10 color-primary-grey font-semi-bold">
                                                                            {{Data.itemLength  === Data.matchCallLanguage.length?"-":"+"}}{{Data.matchCallLanguage.length-2}}
                                                                        </span>
                                                                    </div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <!-- for mobile devices  -->
                                            </div>
                                            <div
                                                *ngIf="Data?.appointmentCategoryId==requestCategory.InterpretationRequest">
                                                <!-- for md,lg,xl,xxl  -->
                                                <ul class="list-group list-group-flush hide-in-sm">
                                                    <li class="list-group-item py-3">
                                                        <div class="col-12 d-flex">
                                                            <div
                                                                class="col-xl-3 col-lg-6 col-md-4 col-4 d-flex border-right">
                                                                <div class="col-6 px-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTOR"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.user?.value?? 'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                                <div class="col-6 pe-2">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPOINTMENTNUMBER"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.appointmentNumber??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xl-9 col-lg-6 col-md-8 col-8  d-flex ps-4 gap-3">
                                                                <div class="col">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.GENDERREQUEST"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.interpreterGenderRequest?.value ?? "APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.GENDERREQUEST.OPTION.NOPREFERENCE"|translate}}</p>
                                                                </div>
                                                                <div class="col">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ONDEMAND"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.scheduleOptionsId ==
                                                                        scheduleOptions.OnDemand?'Yes':'No'}}</p>
                                                                </div>
                                                                <div
                                                                    class="col d-md-none d-xl-block d-lg-block d-xxl-block">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold text-nowrap mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.COMMUNICATIONTYPE"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.communicationType?.value?
                                                                        Data?.communicationType?.value : 'COMMON.NOTAVAILABLE' |  translate}}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    class="col d-md-none d-xl-block d-lg-block d-xxl-block">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold ps-2 mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.SERVICETYPE"
                                                                        | translate}}</p>
                                                                    <p
                                                                        class="f-10 color-primary-grey font-Regular ps-2">
                                                                        {{Data?.service?.value??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                                <div class="col">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.LOCATION"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.location??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item py-3 d-lg-none d-xl-none d-xxl-none">
                                                        <div class="col-6 d-flex">
                                                            <div class="col px-2">
                                                                <p
                                                                    class="f-10 color-primary-grey font-semi-bold text-nowrap mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.COMMUNICATIONTYPE"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.communicationType?.value?
                                                                    Data?.communicationType?.value : 'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                            <div class="col">
                                                                <p
                                                                    class="f-10 color-primary-grey font-semi-bold ps-2 mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.SERVICETYPE"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular ps-2">
                                                                    {{Data?.service?.value??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li class="list-group-item py-3">
                                                        <div class="col-12 d-flex">
                                                            <div
                                                                class="col-xl-3 col-lg-6 col-md-4 col-4 d-flex border-right d-md-none d-lg-flex d-xl-flex gap-2">
                                                                <div class="col-4 px-2">
                                                                    <p
                                                                        class="f-10 text-nowrap color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.INTERPRETER"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{ Data?.interpreter?.fullName ?
                                                                        Data.interpreter.fullName :
                                                                        ('APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.UNASSIGNED'
                                                                        | translate) }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="col-xl-9 col-lg-6 col-md-8 col-8 d-flex gap-3 ps-4">
                                                                <div class="col">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTNOTES"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.requesterNote??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="userDetails?.organizations[0]?.role?.value!= roles.Requester">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.INTERPRETERNOTES"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.interpreterNote??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                                <div class="col"
                                                                    *ngIf="userDetails?.organizations[0]?.role.value != roles.Requester">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.INTERNALNOTES"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.internalNote??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                                <!-- for md,lg,xl,xxl  -->

                                                <!-- for mobile device  -->
                                                <ul class="list-group list-group-flush hide-in-large">
                                                    <li class="list-group-item p-0">
                                                        <div class="col d-flex gap-2 list-group-item p-1">
                                                            <div class="col-5 px-2">
                                                                <p class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTOR"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.user?.value}}</p>
                                                            </div>
                                                            <div class="col-7 pe-2">
                                                                <p
                                                                    class="f-10 color-primary-grey font-semi-bold text-nowrap mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.APPOINTMENTNUMBER"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.appointmentNumber}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col d-flex flex-column gap-2 list-group-item p-1">
                                                            <div class="d-flex px-2">
                                                                <div class="col">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.GENDERREQUEST"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.interpreterGenderRequest?.value}}</p>
                                                                </div>
                                                                <div class="col">
                                                                    <p
                                                                        class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                        {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.ONDEMAND"
                                                                        | translate}}</p>
                                                                    <p class="f-10 color-primary-grey font-Regular">
                                                                        {{Data?.scheduleOptionsId ==
                                                                        scheduleOptions.OnDemand?'Yes':'No'}}</p>
                                                                </div>
                                                            </div>
                                                            <div class="col px-2">
                                                                <p class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.LOCATION"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.location??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col d-flex gap-2 list-group-item p-1">
                                                            <div class="col px-2">
                                                                <p
                                                                    class="f-10 color-primary-grey font-semi-bold text-nowrap mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.COMMUNICATIONTYPE"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.communicationType?.value?
                                                                    Data?.communicationType?.value : 'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                            <div class="col">
                                                                <p
                                                                    class="f-10 color-primary-grey font-semi-bold ps-2 mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.SERVICETYPE"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular ps-2">
                                                                    {{Data?.service?.value??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="col d-flex flex-column gap-2 list-group-item p-1">
                                                            <div class="col px-2">
                                                                <p class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.REQUESTNOTES"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.requesterNote??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                            <div class="col px-2">
                                                                <p class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.INTERPRETERNOTES"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.interpreterNote??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                            <div class="col px-2">
                                                                <p class="f-10 color-primary-grey font-semi-bold mb-1">
                                                                    {{"APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST.TABLE.INTERNALNOTES"
                                                                    | translate}}</p>
                                                                <p class="f-10 color-primary-grey font-Regular">
                                                                    {{Data?.internalNote??'COMMON.NOTAVAILABLE' |  translate}}</p>
                                                            </div>
                                                        </div>
                                                    </li>

                                                </ul>
                                                <!-- for mobile device  -->
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="position-absolute top-50 font-semi-bold color-primary-grey start-50 translate-middle"
                    *ngIf="requestList==undefined || null || ''|| requestList.length==0">
                    <span class="">{{'COMMON.NORECORDFOUND' | translate}}</span>
                </div>
                <div
                    class="d-flex pagination-control justify-content-md-end justify-content-center align-items-center px-2 py-2">
                    <div class="d-flex gap-3">  
                        <app-custom-small-dropdown (selectValue)="selectPageSize($event)"> </app-custom-small-dropdown>
                        <app-page-summary [paginationModel]="getrequestsModel?.pagination" [totalRecords]="totalRecords"></app-page-summary>
                        <ngb-pagination [maxSize]="2" [collectionSize]="totalRecords" [(page)]="pageNumber"
                            (pageChange)="onPageChanged($event)"
                            [pageSize]="getrequestsModel?.pagination == null ? totalRecords : getrequestsModel?.pagination?.pageSize">
                            <ng-template ngbPaginationPrevious>{{'COMMON.PAGINATION.PREVIOUS' | translate}}</ng-template>
                            <ng-template ngbPaginationNext>{{'COMMON.PAGINATION.NEXT' | translate}}</ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>