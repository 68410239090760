import { PermissionService } from '../../services/permission.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { Component, ViewEncapsulation, HostListener } from '@angular/core';
import { NavigationEnd, Route, Router } from '@angular/router';
import { Menu, NavService } from '../../services/nav.service';
import { LayoutService } from '../../services/layout.service';
import { LoginService } from 'src/app/auth/login/login.service';
import { ResponseModel } from '../../models/response-model.model';
import { catchError, Subscription, throwError } from 'rxjs';
import { HttpStatusCode } from '@angular/common/http';
import { StorageService } from '../../services/storage.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateEditRequestComponent } from 'src/app/components/appointments-and-scheduling/request-list/pop-ups/create-edit-request/create-edit-request.component';
import { ModalTitle } from '../../enum/common-enum';
import { RequestListService } from 'src/app/components/appointments-and-scheduling/request-list/request-list.service';
import { RealtimeService } from '../../services/realtime.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidebarComponent{
  private permissionDataUpdateSubscription: Subscription;
  private navItemsSubscription: Subscription;
  public iconSidebar;
  public menuItems: Menu[];
  public url: any;
  public fileurl: any;
  loginUserDetails: any;
  selectedLanguage:string;
  modalTitle = ModalTitle;
  isPermissionReceived: boolean;

  // For Horizontal Menu
  public margin: any = 0;
  public width: any = window.innerWidth;
  public leftArrowNone: boolean = true;
  public rightArrowNone: boolean = false;

  constructor(private router: Router, public navServices: NavService,public storageService:StorageService,public requestListService : RequestListService, public realtimeService: RealtimeService,    
    public layout: LayoutService,public loginService:LoginService,private modalService: NgbModal,public permissionService:PermissionService) {
      this.navServices?.items?.next(JSON.parse(JSON.stringify(this.navServices?.MENUITEMS)));   
      this.GetPermissionAccessByUser();
    this.loginUserDetails =  this.storageService.retrieve("userAccountDetails");
    this.selectedLanguage =  this.storageService.retrieve("selectedLanguage");

   
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = event.target.innerWidth - 500;
  }

  ngAfterViewInit() {
    this.subscribeToRealTimeUpdates();
  }

  ngOnInit(){
  }

  subscribeToRealTimeUpdates(): void {
    this.permissionDataUpdateSubscription = this.realtimeService?.permissionChanged$.subscribe(liveData => {
      if (liveData) {
        this.applyPermission(liveData)
      }
    });
  }


  sidebarToggle() {
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }
    languageToggle() {
    this.navServices.language = !this.navServices.language;
  }

  // Active Nave state
  setNavActive(item) {
      this.menuItems.filter(menuItem => {
        if (menuItem !== item) {
          menuItem.active = false;
        }
        if (menuItem.children && menuItem.children.includes(item)) {
          menuItem.active = true;
        }
        if (menuItem.children) {
          menuItem.children.filter(submenuItems => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              menuItem.active = true;
              submenuItems.active = true;
            }
          });
        }
      });
  }

  // Click Toggle menu
  toggletNavActive(item) {
    this.router.navigate([item?.children[0]?.path]) // show default aactive on item load;

    if (!item.active) {
      this.menuItems.forEach(a => {
        if (this.menuItems.includes(item)) {
          a.active = false;
        }
        if (!a.children) { return false; }
        a.children.forEach(b => {
          if (a.children.includes(item)) {
            b.active = false;
          }
        });
      });
    }
    item.active = !item.active;
  }


  // For Horizontal Menu
  scrollToLeft() {
    if (this.margin >= -this.width) {
      this.margin = 0;
      this.leftArrowNone = true;
      this.rightArrowNone = false;
    } else {
      this.margin += this.width;
      this.rightArrowNone = false;
    }
  }

  scrollToRight() {
    if (this.margin <= -3051) {
      this.margin = -3464;
      this.leftArrowNone = false;
      this.rightArrowNone = true;
    } else {
      this.margin += -this.width;
      this.leftArrowNone = false;
    }
  }


  GetPermissionAccessByUser(){
    this.forSettingActiveMenu();
    this.loginService.getAccessAccordingToPermission().pipe(catchError((err) => this.handleError(err)))
    .subscribe((res:ResponseModel) => {
      if (res.isSucceeded === true) {
        this.applyPermission(res?.response);
      }
    });
  }

  applyPermission(res){
    this.permissionService.permissionViewModel = res;
    this.permissionService.permissionAfterResponse.next(this.permissionService.permissionViewModel);
    this.isPermissionReceived = true;
    this.forSettingActiveMenu();
  }

  forSettingActiveMenu(){
    this.navItemsSubscription = this.navServices.items.subscribe(menuItems => {
      this.menuItems = this.getMenuListByPermission(menuItems);
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          menuItems.filter(items => {
            if (items.path === event.url) {
              this.setNavActive(items);
            }
            if (!items.children) { return false; }
            items.children.filter(subItems => {
              if (subItems.path === event.url) {
                this.setNavActive(subItems);
              }
              if (!subItems.children) { return false; }
              subItems.children.filter(subSubItems => {
                if (subSubItems.path === event.url) {
                  this.setNavActive(subSubItems);
                }
              });
            });
          });
        }
      });
    });
  }

  openCreateEditRequestPopUp() {
    const modalOption: NgbModalOptions = {
        centered: true,
        backdrop: "static",
        keyboard: false,
        size: "md",
        windowClass: "addEditUserList",
    };
    const modalRef = this.modalService.open(
        CreateEditRequestComponent,
        modalOption
    );
    modalRef.componentInstance.ModalTitleFromReqList =this.modalTitle.AppointmentForm;
    modalRef.result.then((res) => {
      if (res) {
        this.requestListService.triggerLoadReq();
      } 
  });
}

  getMenuListByPermission(menuItems: Menu[]): Menu[] {
    if(!this.permissionService?.permissionViewModel?.Dashboard?.AppointmentStatistics){
      menuItems = menuItems?.filter(s=> !['SIDEBAR.MENUITEMS.MENUITEM.DASHBOARD'].includes(s?.title));
    }

    if(!this.permissionService?.permissionViewModel?.Appointments){
      menuItems = menuItems?.filter(s=> !['SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING'].includes(s?.title));
    }
    else{
      if(!this.permissionService?.permissionViewModel?.Appointments?.ViewAppointmentRequests){
        const parentItem = menuItems?.find(item => item?.title === "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING");
        // If the parent item exists, filter its children to remove the specific child item
        if (parentItem && this.isPermissionReceived) {
            parentItem.children = parentItem?.children?.filter(child => child?.title !== "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.REQUESTLIST");
        }
      }
      if(!this.permissionService.permissionViewModel?.Appointments?.ViewAppointmentRequests){
        const parentItem = menuItems?.find(item => item?.title === "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING");
        // If the parent item exists, filter its children to remove the specific child item
        if (parentItem && this.isPermissionReceived) {
            parentItem.children = parentItem?.children?.filter(child => child?.title !== "SIDEBAR.MENUITEMS.MENUITEM.APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR");
        }
      }
    }
    if(!this.permissionService.permissionViewModel?.Reports?.ViewReports){
      menuItems = menuItems?.filter(s=> !['SIDEBAR.MENUITEMS.MENUITEM.REPORTS'].includes(s.title));
    }

    if(!this.permissionService.permissionViewModel?.User_Management && !this.permissionService.permissionViewModel?.Form_Settings && !this.permissionService?.permissionViewModel?.Activity_Logs){
      menuItems = menuItems?.filter(s=> !['SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS'].includes(s.title));
    }
    else{
      if(!this.permissionService.permissionViewModel?.User_Management?.ViewUsers){
        const parentItem = menuItems.find(item => item.title === "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS");
        // If the parent item exists, filter its children to remove the specific child item
        if (parentItem && this.isPermissionReceived) {
            parentItem.children = parentItem.children?.filter(child => child.title !== "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS.SUBMENUITEM.USERMANAGEMENT");
        }
      }
      if((!this.permissionService?.permissionViewModel?.Form_Settings?.ViewFormSettings && !this.permissionService.permissionViewModel?.Form_Settings?.ViewOrganizations)){
        const parentItem = menuItems?.find(item => item?.title === "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS");
        // If the parent item exists, filter its children to remove the specific child item
        if (parentItem && this.isPermissionReceived) {
            parentItem.children = parentItem.children.filter(child => child?.title !== "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS.SUBMENUITEM.FORMSETTINGS");
        }
      }
      if(!this.permissionService?.permissionViewModel?.Activity_Logs?.ViewLogs){
        const parentItem = menuItems?.find(item => item?.title === "SIDEBAR.MENUITEMS.MENUITEM.ADMINSETTINGS");
        // If the parent item exists, filter its children to remove the specific child item
        if (parentItem && this.isPermissionReceived) {
            parentItem.children = parentItem?.children?.filter(child => child?.title !== "SIDEBAR.MENUITEMS.MENUITEM.AUDITLOG");
        }
      }
    }
    return menuItems;
  }

  private handleError(error: any) {
    if (error.status === HttpStatusCode.Forbidden) {
    } else {
      throwError(error.error.message);
    }

    return throwError(error);
  }

  ngOnDestroy(): void {
    this.permissionDataUpdateSubscription?.unsubscribe();
    this.navItemsSubscription?.unsubscribe();
  }

}
