import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { NgbDate, NgbDateStruct, NgbInputDatepicker, NgbCalendar, NgbDateParserFormatter, NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NewRangeDatePickerComponent } from '../new-range-date-picker/new-range-date-picker.component';
import { DateModel } from 'src/app/components/dashboard/dashboard.model';

export class SelectedDates {
	startDate: NgbDate;
	endDate: NgbDate;
}
@Component({
  selector: 'app-range-datepicker',
  templateUrl: './range-datepicker.component.html',
  styleUrls: ['./range-datepicker.component.scss'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm },DatePipe ],
  encapsulation: ViewEncapsulation.None,
})
export class RangeDatepickerComponent {

@Input() displayMonths;
@Input() datepickername;
@Input() dynamic_class: string;
@Input() inputDate: string = "";
@Output() selectedDates: EventEmitter<SelectedDates> = new EventEmitter<SelectedDates>();

	maxDate: NgbDateStruct;
	minDate: NgbDateStruct;

	hoveredDate: NgbDate | null = null;

	 fromDate: NgbDate | null;
	 toDate: NgbDate | null;
	 @Input() formatMMddyyyy:boolean=false;
	 @Input() fromDateFromParent;
	 @Input() toDateFromParent;
	 @Input() datePickerLabel;
	 @Input() isRequired;
	 @Input() isMinDate?:boolean = true;
	 @Input() allowBoth?:boolean = false;
	 @Input() isOnlySingleDateSelect?:boolean = false;
	 @Input() isResetIconVisible?:boolean = false;


	@ViewChild("datepicker") dp: NgbInputDatepicker;
	getDashboardData: any;
	selectedDate: string;
  
	constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private datePipe: DatePipe,  private modalService : NgbModal) {
		this.onClear();
		this.disableAutocomplete();
	}

	getMinSelectableDate(): NgbDate {
		if(this.isMinDate){
			const today = this.calendar.getToday();
			return NgbDate.from({ year: today.year, month: today.month, day: today.day });
		}
	  }

	  disableAutocomplete() {
		const formFields = document.getElementsByTagName('input');
		for (let i = 0; i < formFields.length; i++) {
		  formFields[i].setAttribute('autocomplete', 'off');
		}
	  }

	// getMaxSelectableDate(): NgbDateStruct {
	// 	const currentDate = new Date();
	// 	const threeMonthsLater = new Date(currentDate.getFullYear(), currentDate.getMonth() + 3, currentDate.getDate());
	  
	// 	return {
	// 	  year: threeMonthsLater.getFullYear(),
	// 	  month: threeMonthsLater.getMonth() + 1, // Months are zero-based, so add 1
	// 	  day: threeMonthsLater.getDate()
	// 	};
	//   }

	ngOnInit(): void {
		if(this.fromDateFromParent || this.toDateFromParent){
			this.assignToDatePicker()
		}
	}

	checkDisabled(){
		if(this.allowBoth){
			return !(this.fromDate || this.toDate) && this.isInputValid(this.inputDate)== false;
		}
		else{
			return !(this.fromDate && this.toDate) && this.isInputValid(this.inputDate)== false;
		}
	}

	onDateSelection(date: NgbDate) {
		if (!this.fromDate && !this.toDate) {
			this.fromDate = date;
		} 
		else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
			this.toDate = date;
		} 
		else {
			this.toDate = null;
			this.fromDate = date;
		}
		if(this.fromDate){
			let startDate = this.formatter.format(this.fromDate);
			const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'MM/dd/yyyy':'MM/dd/yyyy');
			this.inputDate = `${datefrom}`;
		}

		if(this.fromDate && this.toDate){
		let startDate = this.formatter.format(this.fromDate);
    	let endDate = this.formatter.format(this.toDate);
		const datefrom = this.datePipe.transform(startDate,this.formatMMddyyyy? 'MM/dd/yyyy':'MM/dd/yyyy');
		const dateTo = this.datePipe.transform(endDate, this.formatMMddyyyy? 'MM/dd/yyyy':'MM/dd/yyyy');
		this.inputDate = `${datefrom} - ${dateTo}`;
		}
		this.selectedDates.emit({startDate: this.fromDate,endDate: this.toDate});
	}

	isHovered(date: NgbDate) {
		return (
			this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
		);
	}
	isInside(date: NgbDate) {
		return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
	}
	isRange(date: NgbDate) {
		return (
			date.equals(this.fromDate) ||
			(this.toDate && date.equals(this.toDate)) ||
			this.isInside(date) ||
			this.isHovered(date)
		);
	}
	isStartDate(date: NgbDate) {
		return date.equals(this.fromDate);
	}
	isEndDate(date: NgbDate) {
		return date.equals(this.toDate);
	}
	onClear() {
		this.inputDate = null;
		this.fromDate = null;
		this.toDate = null;
		this.selectedDates.emit({
			startDate: this.fromDate,
			endDate: this.toDate
		});
		if(this.dp){
			this.dp.startDate = this.calendar.getToday();
		}
		
	}

	clearSelection(event){
		this.inputDate = null;
		this.fromDate = null;
		this.toDate = null;
		this.selectedDates.emit({startDate: this.fromDate,endDate: this.toDate});
		if(this.dp){this.dp.startDate = this.calendar.getToday();}
	}

	clearDates(){
		this.inputDate = null;
		this.fromDate = null;
		this.toDate = null;
	}
	onApply() {
		this.selectedDates.emit({
			startDate: this.fromDate,
			endDate: this.toDate
		});
		this.dp.close();
	}

    // from parent (edit)start
	assignToDatePicker(){
		if(this.fromDateFromParent){
			const fromDateParse = this.formatter.parse(this.fromDateFromParent);
			const fromNgbDate = new NgbDate(fromDateParse.year, fromDateParse.month, fromDateParse.day);
			this.fromDate=fromNgbDate;
			const startDate = this.formatter.format(fromNgbDate);
			const datefrom = this.datePipe.transform(startDate, 'MM/dd/yyyy');
			this.inputDate = `${datefrom} - ${datefrom}`;
		} ;
		 if(this.toDateFromParent){
			const toDateParse = this.formatter.parse(this.toDateFromParent);
			const toNgbDate= new NgbDate(toDateParse.year, toDateParse.month, toDateParse.day);
			this.toDate    = toNgbDate;
			const startDate  = this.formatter.format(this.fromDate);
			const endDate    = this.formatter.format(this.toDate);
			const datefrom = this.datePipe.transform(startDate, 'MM/dd/yyyy');
			const dateTo   = this.datePipe.transform(endDate, 'MM/dd/yyyy');
			this.inputDate = `${datefrom} - ${dateTo}`;
		}
	}

	mouseEnter(){
	// 	if(this.fromEdit){
	// 		this.fromDate = null;
    //    }
	}

	onDateRangeInputChange(inputValue: string) {
		const dates = inputValue.split(' - '); // Assuming date range is entered as "start - end"
		if (dates.length === 2) {
			if(this.isInputValid(inputValue)){
				const startDate = this.parseDateString(dates[0]);
				const endDate = this.parseDateString(dates[1]);
		  
				if (startDate && endDate) {
				  this.fromDate = startDate;
				  this.toDate = endDate;
				  const startDate1  = this.formatter.format(this.fromDate);
				  const endDate1   = this.formatter.format(this.toDate);
				  const datefrom = this.datePipe.transform(startDate1, 'MM/dd/yyyy');
				  const dateTo   = this.datePipe.transform(endDate1, 'MM/dd/yyyy');
				  this.inputDate = `${datefrom} - ${dateTo}`;
				}
			}
			else{
				this.fromDate = null;
				this.toDate = null;
				this.inputDate = inputValue;
			}
		}
		this.selectedDates.emit({
			startDate: this.fromDate,
			endDate: this.toDate
		});
		this.checkDisabled();
	  }
	
	  private parseDateString(dateString: string): NgbDate | null {
		const parts = dateString.split('/');
		if (parts.length === 3) {
		  const year = parseInt(parts[2], 10);
		  const month = parseInt(parts[0], 10);
		  const day = parseInt(parts[1], 10);
		  return new NgbDate(year, month, day);
		}
		return null;
	  }

	  isInputValid(input: string): boolean {
		// Define the regex pattern for the format MM/dd/yyyy - MM/dd/yyyy
		const patternForRange = /^\d{2}\/\d{2}\/\d{4}\s*-\s*\d{2}\/\d{2}\/\d{4}$/;
		// Test if the input matches the pattern
		return patternForRange.test(input);
	   }

	  openDatepicker(event?) {
		event?.stopPropagation();
		if(this.dp?.isOpen()) return;
		this.dp.open();
		if(this.fromDate){
		  this.dp.navigateTo(this.fromDate)
		}else{
		  const currentDate = new Date();
		  this.dp.navigateTo({
			year: currentDate.getFullYear(),
			month: currentDate.getMonth() + 1, // Months are zero-based, so add 1
			day: currentDate.getDate()
		  })
		}
	  } 

	  openNewRangeDatePicker() {
		const modalOption: NgbModalOptions = {
			centered: true,
			backdrop: "static",
			keyboard: false,
			size: "md",
		};
		var modalRef = this.modalService.open(NewRangeDatePickerComponent, modalOption);
		if(this.inputDate){
			const inputStartAndEndDate = this.inputDate?.split(' - '); // Assuming date range is entered as "start - end"
			modalRef.componentInstance.fromDate = this.formatter?.parse(this.datePipe?.transform(inputStartAndEndDate[0],'yyyy-MM-dd'));
			modalRef.componentInstance.toDate = this.formatter?.parse(this.datePipe?.transform(inputStartAndEndDate[1],'yyyy-MM-dd'));
		}
		modalRef.componentInstance.isOnlySingleDateSelect = this.isOnlySingleDateSelect;
		modalRef.result.then((res) => {
			if (res) {
				let startDate = this.formatter?.format(res?.fromDate);
				let endDate = this.formatter?.format(res?.toDate);
				const datefrom = this.datePipe?.transform(startDate, this.formatMMddyyyy ? 'MM/dd/yyyy' : 'MM/dd/yyyy');
				const dateTo = this.datePipe?.transform(endDate, this.formatMMddyyyy ? 'MM/dd/yyyy' : 'MM/dd/yyyy');
				this.inputDate = `${datefrom} - ${dateTo}`;
				this.selectedDates?.emit({
					startDate: res?.fromDate,
					endDate: res?.toDate
				});
			}
		});
	}
	
}