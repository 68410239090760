export class LoadDropdownData {
}

export enum DropdownSections {
    Genders = 1,
    Groups = 2,
    Organizations = 3,
    DialCodes = 4,
    Roles = 5,
    Timezones = 6,
    UILanguages = 7,
    RequestCategories = 8,
    ScheduleOptions = 9,
    RequestersOfOrganization = 10,
    CommunicationTypes = 11,
    Services = 12,
    Languages = 13,
    States = 14,
    ReasonsForCancellation = 15,
    ReasonsForEndedEarly = 16,
    AppointmentInterpreters = 17,
    AuditLogSections = 18,
    YesNo = 19,
    statuses = 20
}

export class GetDropdownIdModel{
    ids:number[]=[];
}

export class CommonDropDownModel{
    id:number|string;
    value:string;
    description?:string;
    category?:string;
    userId?:string;
    rfcCode?:string;
    code?:string;
    organizationCategory?:number| string | null;
    roleCategory?:number| string | null;
    organizationId?:number | null;
    parentId?:number | null;
    phoneNumberLength?: number | null;
}

export class CommonGetDropdownModel{
    roles:CommonDropDownModel[]=[];
    timezones:CommonDropDownModel[]=[];
    genders:CommonDropDownModel[]=[];
    organizations:CommonDropDownModel[]=[];
    groups:CommonDropDownModel[]=[];  
    dialCodes: CommonDropDownModel[]=[]; 
    uiLanguages :CommonDropDownModel[]=[];
    requestCategories:CommonDropDownModel[] = [];
    scheduleOptions:CommonDropDownModel[] = [];
    communicationTypes:CommonDropDownModel[] = [];
    requestersOfOrganization:CommonDropDownModel[] = [];
    services:CommonDropDownModel[] = [];
    languages:CommonDropDownModel[] = [];
    onDemand:CommonDropDownModel[] = [];
    states:CommonDropDownModel[] = [];
    appointmentInterpreters:CommonDropDownModel[] = [];
    sections:CommonDropDownModel[] = [];                                     
    reasonsForEndedEarly:CommonDropDownModel[] = [];                                     
    reasonsForCancellation:CommonDropDownModel[] = [];                                    
    auditLogSections:CommonDropDownModel[] = [];         
    statuses:CommonDropDownModel[] = [];                            
}