import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { EntryState } from '../../enum/common-enum';
import * as moment from 'moment';

@Component({
  selector: 'app-multiselect-dropdown',
  templateUrl: './multiselect-dropdown.component.html',
  styleUrls: ['./multiselect-dropdown.component.scss']
})
export class MultiselectDropdownComponent{
  @Input() dynamic_class:string;
  @Output("SelectOutputEvent") SelectOutputEvent: EventEmitter<any> = new EventEmitter();
  @Input() addIcon = true;
  @Input() placeholderName:string;
  selectedOption:any;
  @Input() dropDownLabel:string;
  @Input() dropDownList:any[];
  bindedDropdownList:any[] = [];
  @Input() valueFromEditGroup:any;

  isClicked = false;
  
  addUserList:any[]=[];

  dropdownOpen = false;

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  onChecked(item){
    var index = this.addUserList?.findIndex((userItem)=>userItem.id == item?.id);
    if(index == -1){
      item.entryState = EntryState.POST;
      item.dateCreated  = moment().format('MM/DD/yyyy');
      this.addUserList.push(item);
    }
    else{
      this.addUserList[index].entryState = EntryState.DELETE;
    }
  }

  ngOnInit(){

  }
  getDropdownHeight() {
    return this.bindedDropdownList && this.bindedDropdownList.length < 6 ? 'auto' : '11rem';
  }

  ngOnChanges() {
    if(this.bindedDropdownList.length == 0){
      this.bindedDropdownList = this.dropDownList?.map(item => ({
        id: item?.id,
        name: item?.fullName,
        fullName : item?.fullName,
        // role: item?.organization?.role,
        role: item?.roleName,
        entryState: EntryState.NONE,
        firstName: item?.firstName,
        lastName: item?.lastName,
        isChecked: false
      })) || [];
    } 
    else{
      if (this.valueFromEditGroup !== undefined) {
        this.bindedDropdownList?.forEach((item,index) => {
          
          if(Array.isArray(this.valueFromEditGroup)){
            const matchingItem = this.valueFromEditGroup.find(editItem => editItem.id === item.id);
            if (matchingItem !== undefined) {
              item.isChecked = matchingItem.isChecked;
              item.isAlreadyInGroup = matchingItem.isAlreadyInGroup;
            }
          }
          else{
            if (this.valueFromEditGroup.name === item.name) {
              item.isChecked = this.valueFromEditGroup.isChecked;
              item.isAlreadyInGroup = this.valueFromEditGroup.isAlreadyInGroup;
            }
          }

        });
      }
    }

  }
  
  @ViewChild('multi') dropdownContainer: ElementRef;

  closeDropdown() {
    this.dropdownOpen = false;
  }
  
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (this.dropdownContainer && !this.dropdownContainer.nativeElement.contains(event.target as Node)) {
      this.closeDropdown();
    }
  }

  addSelectedItems(){
    // const addUserListCopy = JSON.parse(JSON.stringify(this.addUserList));
    this.isClicked = true;
    this.addUserList?.forEach(a => a.isAlreadyInGroup = true);
    this.SelectOutputEvent.emit(this.addUserList);
    this.dropdownOpen = !this.dropdownOpen;
    setTimeout(() => {this.isClicked = false;}, 200);
  }

}
