<app-load-dropdown-data [ids]="dropdownIds" (emitDropdownData)="fillDropdownData($event)"></app-load-dropdown-data>
<app-breadcrumb [title]="'SIDEBAR.MENUITEMS.MENUITEM.DASHBOARD'"
  [active_item]="'SIDEBAR.MENUITEMS.MENUITEM.DASHBOARD'"></app-breadcrumb>
  <div [ngClass]="{'invisible':!(permissionService?.permissionViewModel?.Dashboard?.AppointmentStatistics)}">
<form class="d-flex flex-wrap flex-md-nowrap flex-sm-nowrap pb-3">
  <div class="col-lg-11 col-xl-11 col-md-11  flex-wrap  d-flex">
    <div class=" pe-lg-2 px-lg-0 px-2 col-6 col-lg-2 col-md-2 col-xl-2 col-sm-6">
      <app-dropdown [showPlaceholderText]="'REPORTS.REPORTING.TABLE.TABLEHEADER.REQUESTTYPE' | translate"
        [dropDownList]="loadDropdownDataService.commonGetDropdownModel.requestCategories"
        [dropdownValue]="getDashboardData.appointmentCategoryId"
        (dropdownValueEmit)="getDashboardData.appointmentCategoryId=$event;disableLanguageAndClear($event ,languageDropdown);loadDashboardData()"
        [id]="'requestCategory'" [dynamic_class]="'dashboardDropdown'"></app-dropdown>
    </div>
    <div class="px-2  col-6 d-lg-none d-xl-none d-md-none  d-block">
      <app-button [buttonLabel]="'Clear Filters'" class="d-block" [buttonClass]="'dashboardClearButton ps-2 pe-2 w-100'"
        (buttonOutputEvent)="clearFilters()"></app-button>
    </div>
    <div class="px-2 py-2 py-xl-0 py-lg-0 py-md-0  col-6 col-lg-2 col-md-2 col-xl-2 col-sm-6">
      <!-- <app-dropdown [showPlaceholderText]="'Language'" [dropDownList]="loadDropdownDataService.commonGetDropdownModel.languages" [dropdownValue]="getDashboardData.languageVariantId" [id]="'languages'" [dynamic_class]="'dashboardDropdown'"></app-dropdown> -->
      <app-custom-dropdown #languageDropdown
        [isDisabled]="getDashboardData?.appointmentCategoryId == appointementRequestCategory?.LinguisticMatchCall"
        [id]="'languages'" [isRequired]="false" (buttonOutputEvent)="addNewLanguages($event);"
        [singleSelectedlanguage]="seletedlanguage" [languageDropDownData]="languagesListData" [addLanguageIcon]="false"
        [dynamic_class]="'dashboardDropdown'"></app-custom-dropdown>

    </div>
    <div class="px-2 py-2 py-xl-0 py-lg-0 py-md-0  col-6 col-lg-2 col-md-2 col-xl-2 col-sm-6">
      <app-dropdown
        [showPlaceholderText]="'USERMANAGEMENT.TABLIST.TABLABEL.USERLIST.TABLE.TABLEHEADER.ORGANIZATION'| translate"
        [dropDownList]="loadDropdownDataService.commonGetDropdownModel.organizations"
        [dropdownValue]="getDashboardData.organizationId"
        (dropdownValueEmit)="getDashboardData.organizationId=$event;loadDashboardData()" [id]="'organizations'"
        [dynamic_class]="'dashboardDropdown'"></app-dropdown>
    </div>
    <div class="px-2  col-6 col-lg-2 col-md-2 col-xl-2 col-sm-6">
      <app-dropdown
        [showPlaceholderText]="'USERSETTINGS.TABLIST.TABLABEL.USERDETAILS.LABEL.SERVICES.COMMUNICATIONTYPE' | translate"
        [dropDownList]="loadDropdownDataService.commonGetDropdownModel.communicationTypes"
        [dropdownValue]="getDashboardData.communicationTypeId"
        (dropdownValueEmit)="getDashboardData.communicationTypeId=$event;loadDashboardData()" [id]="'communicationType'"
        [dynamic_class]="'dashboardDropdown'"></app-dropdown>
    </div>
    <div class="px-2  col-6 col-lg-2 col-md-2 col-xl-2 col-sm-6">
      <app-dropdown #dateRange [showPlaceholderText]="'REPORTS.REPORTING.FILTERPOPUP.DATERANGE' | translate"
        [dynamic_heightClass]="'heightForDateRange'" [customRange]="true" [dropDownList]="filterDropdownList.dateRange"
        (dropdownValueEmit)="setDateAsPerNoOfDays($event)" (dropdownValueOpenRangeDatePicker)="openNewRangeDatePicker()"
        [dropdownValue]="selectedDates" [currentSelectionDate]="selectedDates" [id]="'DateRange'"
        [dynamic_class]="'dashboardDropdown d-blue'"></app-dropdown>
    </div>

  </div>
  <div class="col-lg-1 col-xl-1 col-md-1 col-sm-1 col-6 d-lg-block d-xl-block d-md-block d-none">
    <app-button [buttonLabel]="'DASHBOARD.BUTTON.CLEARFILTERS'" class="d-block pull-right "
      [buttonClass]="'dashboardClearButton ps-2 pe-2 mx-2'" (buttonOutputEvent)="clearFilters()"></app-button>
  </div>
</form>
<div class="d-lg-flex d-block flex-wrap">
  <div class="col-custom-7 py-2 pe-lg-2 px-lg-0 pe-xl-3 pe-md-2 px-md-2 px-2 ">
    <div class="card mb-0 ">
      <div class="d-flex justify-content-between p-l-20 p-r-20 pt-3">
        <span class="headerTitle">{{"DASHBOARD.REQUESTS" | translate}}</span>
        <span class="headerTitle">{{"DASHBOARD.TITLE.TOTAL" | translate}}:&nbsp;{{totalRequest}}</span>
      </div>
      <div class="card-body radial-progress-card  pt-0 pb-3 px-3">
        <div class="radial-chart-wrap" *ngFor="let item of radialChartList">
          <div id="radial-chart" class="d-flex justify-content-center">
            <apx-chart [series]="[item?.count]" [chart]="data?.chart" [plotOptions]="data?.plotOptions"
              [colors]="[color[item?.label]]" [stroke]="data?.stroke" [responsive]="data?.responsive"></apx-chart>
          </div>
          <label class="statusLabel">{{item?.label | translate}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-custom-5 px-2 ps-md-0 py-2 upcoming-request">
    <div class="card mb-0">
      <div class="card-body d-flex gap-3 justify-content-between overflow-auto flex-wrap flex-sm-wrap flex-md-nowrap">
        <div class=" mb-md-0 mb-sm-0 d-flex align-items-center justify-content-between justify-content-md-start"
          style="width: -webkit-fill-available;">
          <span class="headerTitle text-nowrap">{{"DASHBOARD.UPCOMINGREQUESTS"|translate}}</span>
          <span
            class="badge badge-primary pull-right digits px-2 py-2 ms-3 requeestCount">{{upcomingRequestCount}}</span>
        </div>
        <div class=" d-flex gap-custom upcoming-right-section">

          <div class=" d-flex gap-2 justify-content-between justify-content-md-end  badge-section">
            <div
              class="badge badge-primary pull-right digits px-2 py-2 pull-left d-flex align-items-center all cursor-pointer"
              (click)="getDashboardData.requestsHappensInNextDays = null ;this.loadDashboardData()">{{'COMMON.PAGESIZE.ALL'|translate}}</div>

            <div class="col-3">
              <span class="badge badge-small badge-primary pull-right digits mb-075em cursor-pointer"
                (click)="getDashboardData.requestsHappensInNextDays = 1 ;this.loadDashboardData()">1
                {{'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.DAY' | translate}}</span>
              <span class="badge badge-small badge-primary pull-right digits cursor-pointer"
                (click)="getDashboardData.requestsHappensInNextDays = 7 ;this.loadDashboardData()">7
                {{'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.DAY' | translate}}s</span>
            </div>
            <div class="col-3">
              <span class="badge badge-small badge-primary pull-right digits mb-075em cursor-pointer"
                (click)="getDashboardData.requestsHappensInNextDays = 2 ;this.loadDashboardData()">2
                {{'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.DAY' | translate}}s</span>
              <span class="badge badge-small badge-primary pull-right digits cursor-pointer"
                (click)="getDashboardData.requestsHappensInNextDays = 14; this.loadDashboardData()">14
                {{'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.DAY' | translate}}s</span>
            </div>
            <div class="col-3">
              <span class="badge badge-small badge-primary pull-right digits mb-075em cursor-pointer"
                (click)="getDashboardData.requestsHappensInNextDays = 3;this.loadDashboardData()">3
                {{'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.DAY' | translate}}s</span>
              <span class="badge badge-small badge-primary pull-right digits cursor-pointer"
                (click)="getDashboardData.requestsHappensInNextDays = 30;loadDashboardData()">30
                {{'APPOINTMENT&SCHEDULING.SUBMENUITEM.CALENDAR.DAY' | translate}}s</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-0 mt-4">
      <div class="card-body p-4 pe-1 requestByLanguage">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <span class="headerTitle">{{"DASHBOARD.REQUESTBYLANGUAGE"| translate}}</span>
          <div class="d-flex align-items-center switchButton">
            <div class="color-primary-grey f-12 font-semi-bold pt-2px">{{"DASHBOARD.UPCOMINGREQUEST.REQUESTED" |
              translate}}</div>
            <app-switch [onlySelfAccess]="getDashboardData?.appointmentCategoryId == appointementRequestCategory?.LinguisticMatchCall" class="mx-2" [switchStatus]="getDashboardData?.isRequestLanguages"
              (changeStatus)="getDashboardData.isRequestLanguages = $event;loadDashboardData()"
              [switchTopClass]="'dashboard-toggle'"></app-switch>
            <div class="color-primary-grey f-12 font-semi-bold pt-2px">{{"DASHBOARD.UPCOMINGREQUEST.ACTUAL" |
              translate}}</div>
          </div>
        </div>
        <div class="d-flex languageProgressSection isHide flex-column">
          <div class="col-12 d-flex align-items-center p-t-15" *ngFor="let item of appointmentsByLanguages">
            <div class="col-2 languageName text-truncate">{{item?.language}}</div>
            <div class="col-9 ps-2">
              <div class="progress">
                <div class="progress-bar" role="progressbar" [style.width.%]="(item.count / 100) * 100"
                  aria-valuenow="{{item.count}}" [attr.aria-valuemin]="0" [attr.aria-valuemax]="totalLanguages"></div>
              </div>
            </div>
            <div class="col-1 languageName d-flex justify-content-end">{{item?.count}}</div>
          </div>
        </div>

        <div class="d-flex languageProgressSection isHideMobile flex-column m-t-15">
          <div class="col-12 d-flex align-items-center " *ngFor="let item of appointmentsByLanguages">
            <div class="col-11">
              <div class="languageName text-truncate mb-1">{{item?.language}}</div>
              <div class="progress mb-2">
                <div class="progress-bar" role="progressbar" [style.width.%]="(item.count / 100) * 100"
                  aria-valuenow="{{item.count}}" [attr.aria-valuemin]="0" [attr.aria-valuemax]="totalLanguages"></div>
              </div>
            </div>
            <div class="col-1 languageName d-flex justify-content-end mt-2">{{item?.count}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-custom-7 py-2 pe-lg-2 px-lg-0 pe-xl-3 pe-md-2 px-md-2 px-2 mt-240">
  <div class="card mb-0 p-4">
    <span class="headerTitle">{{ "DASHBOARD.REQUESTBYLOCATION" | translate}}</span>
    <div class="row m-0 p-0">
      <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 p-0">
        <div id="us-map" class="px-2 w-100">
        </div>
        <div class="col-12 d-flex flex-wrap px-2 f-10 font-semi-bold req-map-count gap-3 mb-mobile">
          <div class="col d-flex gap-3">
            <div class="color-primary-grey text-center d-flex align-items-center col text-nowrap">
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g id="Ellipse_2" data-name="Ellipse 2" fill="#fff" stroke="#727272" stroke-width="1">
                  <circle cx="8" cy="8" r="8" stroke="none" />
                  <circle cx="8" cy="8" r="7.5" fill="none" />
                </g>
              </svg>
              0 {{"DASHBOARD.REQUESTS" | translate}}
            </div>
            <div class="color-primary-grey text-center d-flex align-items-center col text-nowrap">
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g id="Ellipse_2" data-name="Ellipse 2" fill="#cce4f2" stroke="#727272" stroke-width="1">
                  <circle cx="8" cy="8" r="8" stroke="none" />
                  <circle cx="8" cy="8" r="7.5" fill="none" />
                </g>
              </svg>
              1-25 {{"DASHBOARD.REQUESTS" | translate}}
            </div>
          </div>
          <div class="col d-flex gap-3">
            <div class="color-primary-grey text-center d-flex align-items-center col text-nowrap">
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g id="Ellipse_2" data-name="Ellipse 2" fill="#82bdde" stroke="#727272" stroke-width="1">
                  <circle cx="8" cy="8" r="8" stroke="none" />
                  <circle cx="8" cy="8" r="7.5" fill="none" />
                </g>
              </svg>
              26-50 {{"DASHBOARD.REQUESTS" | translate}}
            </div>
            <div class="color-primary-grey text-center d-flex align-items-center col text-nowrap">
              <svg class="me-2" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                <g id="Ellipse_2" data-name="Ellipse 2" fill="#047bbc" stroke="#727272" stroke-width="1">
                  <circle cx="8" cy="8" r="8" stroke="none" />
                  <circle cx="8" cy="8" r="7.5" fill="none" />
                </g>
              </svg>
              51+ {{"DASHBOARD.REQUESTS" | translate}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 p-2 mt-md-3 country-list-border">
        <div class="country-list d-flex flex-wrap flex-row flex-md-column flex-md-nowrap">
          <div class="pb-2 d-flex col-6 col-md-12 font-semi-bold" *ngFor="let Item of markersData">
            <div class="col-8 f-10 color-primary-grey p-0">
              {{Item?.state}}
            </div>
            <div class="col-4 f-10 color-primary-grey d-flex justify-content-end pe-2 pe-xxl-0">
              {{Item?.count}}
            </div>
            <!-- <span class="color-primary-grey">{{Item.name}}:{{Item.value}}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>